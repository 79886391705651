import { css, type SerializedStyles } from "@emotion/react";

import type { SizeUnit } from "../utils";

export const margin: {
  (all: SizeUnit): SerializedStyles;
  (block: SizeUnit, inline: SizeUnit): SerializedStyles;
} = (a: SizeUnit, b?: SizeUnit) => css([marginBlock(a), marginInline(b ?? a)]);

export const marginInline = (inline: SizeUnit): SerializedStyles =>
  css({
    marginInlineStart: inline,
    marginInlineEnd: inline,
  });

export const marginBlock = (block: SizeUnit): SerializedStyles =>
  css({
    marginBlockStart: block,
    marginBlockEnd: block,
  });

export const padding: {
  (all: SizeUnit): SerializedStyles;
  (block: SizeUnit, inline: SizeUnit): SerializedStyles;
} = (a: SizeUnit, b?: SizeUnit) =>
  css([paddingBlock(a), paddingInline(b ?? a)]);

export const paddingInline = (inline: SizeUnit): SerializedStyles =>
  css({
    paddingInlineStart: inline,
    paddingInlineEnd: inline,
  });

export const paddingBlock = (block: SizeUnit): SerializedStyles =>
  css({
    paddingBlockStart: block,
    paddingBlockEnd: block,
  });
