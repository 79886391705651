import {
  createContext,
  type ReactNode,
  type RefObject,
  type SetStateAction,
  useContext,
  useRef,
  useState,
} from "react";

const Context = createContext<{
  containerRef: RefObject<HTMLElement>;
  modalStack: unknown[];
  onModalStackChange: (value: SetStateAction<unknown[]>) => void;
} | null>(null);

export const useOverlayContainer = () => {
  const context = useContext(Context);

  if (!context) {
    throw new Error(
      "useOverlayContainer must be used inside OverlayContainerProvider",
    );
  }

  return context;
};

function setVh() {
  document.documentElement.style.setProperty(
    "--vh",
    `${window.innerHeight * 0.01}px`,
  );
}
window.addEventListener("resize", setVh);
setVh();

export const OverlayContainerProvider = ({
  children,
}: {
  children?: ReactNode;
}) => {
  const [stack, setStack] = useState<unknown[]>([]);
  const containerRef = useRef<HTMLElement>(document.body);

  return (
    <Context.Provider
      value={{
        containerRef,
        modalStack: stack,
        onModalStackChange: setStack,
      }}
    >
      {/* {createPortal(
        <div ref={containerRef} css={{ zIndex: 100 }} />,
        document.body,
      )} */}
      <div
        {...(stack.length > 0
          ? {
              inert: "true",
              "aria-hidden": "true",
            }
          : {})}
      >
        {children}
      </div>
    </Context.Provider>
  );
};
