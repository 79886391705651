import type { SVGProps } from "react";

export const DesktopArrowDown = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5303 6.46967C16.8232 6.76256 16.8232 7.23744 16.5303 7.53033L10.5303 13.5303C10.2374 13.8232 9.76256 13.8232 9.46967 13.5303L3.46967 7.53033C3.17678 7.23744 3.17678 6.76256 3.46967 6.46967C3.76256 6.17678 4.23744 6.17678 4.53033 6.46967L10 11.9393L15.4697 6.46967C15.7626 6.17678 16.2374 6.17678 16.5303 6.46967Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const MobileArrowRight = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M9 5.5L15.5 12L9 18.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const DesktopArrowUp = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.46967 13.5303C3.17678 13.2374 3.17678 12.7626 3.46967 12.4697L9.46967 6.46967C9.76256 6.17678 10.2374 6.17678 10.5303 6.46967L16.5303 12.4697C16.8232 12.7626 16.8232 13.2374 16.5303 13.5303C16.2374 13.8232 15.7626 13.8232 15.4697 13.5303L10 8.06066L4.53033 13.5303C4.23744 13.8232 3.76256 13.8232 3.46967 13.5303Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const DesktopMenuClose = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" {...props}>
      <path
        d="M19.7071 7.70711C20.0976 7.31658 20.0976 6.68342 19.7071 6.29289C19.3166 5.90237 18.6834 5.90237 18.2929 6.29289L13 11.5858L7.70711 6.29289C7.31658 5.90237 6.68342 5.90237 6.29289 6.29289C5.90237 6.68342 5.90237 7.31658 6.29289 7.70711L11.5858 13L6.29289 18.2929C5.90237 18.6834 5.90237 19.3166 6.29289 19.7071C6.68342 20.0976 7.31658 20.0976 7.70711 19.7071L13 14.4142L18.2929 19.7071C18.6834 20.0976 19.3166 20.0976 19.7071 19.7071C20.0976 19.3166 20.0976 18.6834 19.7071 18.2929L14.4142 13L19.7071 7.70711Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const MobileMenuSearch = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.6875 17.8446C15.2711 19.0045 13.4602 19.7005 11.4866 19.7005C6.95021 19.7005 3.27272 16.023 3.27272 11.4866C3.27272 6.95022 6.95021 3.27273 11.4866 3.27273C16.023 3.27273 19.7005 6.95022 19.7005 11.4866C19.7005 13.4602 19.0045 15.2712 17.8445 16.6875L21.3058 20.1489C21.6253 20.4684 21.6253 20.9864 21.3058 21.306C20.9863 21.6255 20.4682 21.6255 20.1487 21.3059L16.6875 17.8446ZM18.0642 11.4866C18.0642 15.1193 15.1193 18.0642 11.4866 18.0642C7.85395 18.0642 4.90908 15.1193 4.90908 11.4866C4.90908 7.85396 7.85395 4.90909 11.4866 4.90909C15.1193 4.90909 18.0642 7.85396 18.0642 11.4866Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const LineClose = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <g clipPath="url(#clip0_11697_18554)">
        <path
          d="M18 6L6 18"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6 6L18 18"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_11697_18554">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const LineCloseCircle = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <g clipPath="url(#clip0_11734_25347)">
        <circle opacity="0.5" cx="12" cy="12" r="10" fill="currentColor" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.1429 9.28033C16.4358 8.98744 16.4358 8.51256 16.1429 8.21967C15.85 7.92678 15.3752 7.92678 15.0823 8.21967L12.1813 11.1206L9.28033 8.21967C8.98744 7.92678 8.51256 7.92678 8.21967 8.21967C7.92678 8.51256 7.92678 8.98744 8.21967 9.28033L11.1206 12.1813L8.21967 15.0823C7.92678 15.3752 7.92678 15.85 8.21967 16.1429C8.51256 16.4358 8.98744 16.4358 9.28033 16.1429L12.1813 13.242L15.0823 16.1429C15.3752 16.4358 15.85 16.4358 16.1429 16.1429C16.4358 15.85 16.4358 15.3752 16.1429 15.0823L13.242 12.1813L16.1429 9.28033Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_11734_25347">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const CircleDefault = (props: SVGProps<SVGSVGElement>) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
    <circle cx="9" cy="9" r="8" stroke="#B2B9BE" strokeWidth="1.5" />
  </svg>
);

export const CircleChecked = (props: SVGProps<SVGSVGElement>) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
    <circle cx="9" cy="10" r="8" stroke="#B2B9BE" strokeWidth="1.5" />
    <circle cx="9" cy="10" r="4" fill="#5194C1" />
  </svg>
);

export const CheckboxNormal = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.7483 4H8.25081C5.63805 4 4 5.84995 4 8.46789V15.5321C4 18.1501 5.63027 20 8.25081 20H15.7475C18.3689 20 20 18.1501 20 15.5321V8.46789C20 5.84995 18.3689 4 15.7483 4Z"
        stroke="currentColor"
        strokeWidth={props.strokeWidth ?? 1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ChevronRight = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="6" height="10" viewBox="0 0 6 10" fill="none" {...props}>
      <path
        d="M1 9L5 5L1 1"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const MobileListingCalendar = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M11.4 3.7C11.4 3.3134 11.7134 3 12.1 3C12.4866 3 12.8 3.3134 12.8 3.7V4.4H14.2C15.7464 4.4 17 5.6536 17 7.2H3C3 5.6536 4.2536 4.4 5.8 4.4H7.2V3.7C7.2 3.3134 7.5134 3 7.9 3C8.2866 3 8.6 3.3134 8.6 3.7V4.4H11.4V3.7Z"
        fill="#B2B9BE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 8.6H17V14.2C17 15.7464 15.7464 17 14.2 17H5.8C4.2536 17 3 15.7464 3 14.2V8.6ZM4.96 12.8C4.65072 12.8 4.4 13.0507 4.4 13.36V13.64C4.4 13.9493 4.65072 14.2 4.96 14.2H6.64C6.94928 14.2 7.2 13.9493 7.2 13.64V13.36C7.2 13.0507 6.94928 12.8 6.64 12.8H4.96ZM8.6 13.36C8.6 13.0507 8.85072 12.8 9.16 12.8H10.84C11.1493 12.8 11.4 13.0507 11.4 13.36V13.64C11.4 13.9493 11.1493 14.2 10.84 14.2H9.16C8.85072 14.2 8.6 13.9493 8.6 13.64V13.36ZM4.96 10C4.65072 10 4.4 10.2507 4.4 10.56V10.84C4.4 11.1493 4.65072 11.4 4.96 11.4H6.64C6.94928 11.4 7.2 11.1493 7.2 10.84V10.56C7.2 10.2507 6.94928 10 6.64 10H4.96ZM8.6 10.56C8.6 10.2507 8.85072 10 9.16 10H10.84C11.1493 10 11.4 10.2507 11.4 10.56V10.84C11.4 11.1493 11.1493 11.4 10.84 11.4H9.16C8.85072 11.4 8.6 11.1493 8.6 10.84V10.56ZM13.36 10C13.0507 10 12.8 10.2507 12.8 10.56V10.84C12.8 11.1493 13.0507 11.4 13.36 11.4H15.04C15.3493 11.4 15.6 11.1493 15.6 10.84V10.56C15.6 10.2507 15.3493 10 15.04 10H13.36Z"
        fill="#B2B9BE"
      />
    </svg>
  );
};

export const CheckboxFilled = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.7483 4H8.25081C5.63805 4 4 5.84995 4 8.46789V15.5321C4 18.1501 5.63027 20 8.25081 20H15.7475C18.3689 20 20 18.1501 20 15.5321V8.46789C20 5.84995 18.3689 4 15.7483 4Z"
        stroke="currentColor"
        strokeWidth={props.strokeWidth ?? 1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 12.5L10.6674 15L16 10"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const DesktopPopupDone = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M4 10.5L8.5 15L16 5.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const DesktopArrowLeft = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5303 16.5303C13.2374 16.8232 12.7626 16.8232 12.4697 16.5303L6.46967 10.5303C6.32902 10.3897 6.25 10.1989 6.25 10C6.25 9.80109 6.32902 9.61032 6.46967 9.46967L12.4697 3.46967C12.7626 3.17678 13.2374 3.17678 13.5303 3.46967C13.8232 3.76256 13.8232 4.23744 13.5303 4.53033L8.06066 10L13.5303 15.4697C13.8232 15.7626 13.8232 16.2374 13.5303 16.5303Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const DesktopArrowRight = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.46967 3.46967C6.76256 3.17678 7.23744 3.17678 7.53033 3.46967L13.5303 9.46967C13.671 9.61032 13.75 9.80109 13.75 10C13.75 10.1989 13.671 10.3897 13.5303 10.5303L7.53033 16.5303C7.23744 16.8232 6.76256 16.8232 6.46967 16.5303C6.17678 16.2374 6.17678 15.7626 6.46967 15.4697L11.9393 10L6.46967 4.53033C6.17678 4.23744 6.17678 3.76256 6.46967 3.46967Z"
        fill="currentColor"
      />
    </svg>
  );
};
