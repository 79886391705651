import {
  AxiosError,
  type AxiosInstance,
  type AxiosRequestConfig,
  type AxiosResponse,
} from "axios";

import { FetchError, HttpError } from "./errors";

export type CreateApiOptions = {
  instance: AxiosInstance;
};

/**
 * @example
 * ```ts
 * await api({ url: "...", method: "GET" }, {
 *   200: async (r) => ({
 *     ok: true as const,
 *     data: await r.json(),
 *   }),
 *   400: async (r) => ({
 *     ok: false as const,
 *     error: await r.json(),
 *   }),
 * });
 * ```
 */
export const createApi = ({ instance }: CreateApiOptions) => {
  return async <D = any, H extends Handlers<D> = Handlers<D>>(
    requestConfig: AxiosRequestConfig,
    handlers: H,
  ): Promise<Result<H>> => {
    const result = await instance
      .request<D>(requestConfig)
      .catch((error: Error | AxiosError<D> | HttpError) => {
        if (error instanceof HttpError) {
          throw error;
        }

        if ("response" in error) {
          return error;
        }
        throw new FetchError(error.message);
      });

    const response = result instanceof AxiosError ? result.response : result;

    const handler = handlers[response?.status ?? -1];
    if (handler && response) {
      return handler(response) as Promise<Result<H>>;
    }

    throw new HttpError({ error: result });
  };
};

type Handler<T> = (_: AxiosResponse<T>) => Promise<T>;

type Handlers<T> = {
  [K in number]?: Handler<T>;
};

type Result<H> = H extends {
  [K in number]?: (_: AxiosResponse) => Promise<infer R>;
}
  ? R
  : never;
