import type { AxiosInstance } from "axios";
import { createContext, type ReactNode, useContext } from "react";

import { createApi, type CreateApiOptions } from "./api";

const Context = createContext<{
  api: ReturnType<typeof createApi>;
  instance: AxiosInstance;
} | null>(null);

export const useApi = () => {
  const context = useContext(Context);

  if (!context) {
    throw new Error("useApi requires ApiContext in the react tree");
  }

  return context;
};

export const ApiContext = ({
  children,
  instance,
}: {
  children: ReactNode;
} & CreateApiOptions) => {
  return (
    <Context.Provider
      value={{
        api: createApi({
          instance,
        }),
        instance,
      }}
    >
      {children}
    </Context.Provider>
  );
};
