export const SuspenseTrigger = () => {
  throw new Promise(() => {});
};

const promises = new Map();
export function useSuspense<T>(
  key: string,
  callback: () => Promise<T>,
  enabled?: boolean,
) {
  if (!enabled) return undefined;
  const meta = promises.get(key) || {};

  // for any new promise
  if (!meta.status) {
    meta.status = "pending"; // set it as pending
    const promise = callback();
    meta.promise = promise;
    promise.then((data) => {
      // when resolved, store the data
      meta.status = "resolved";
      meta.data = data;
    });
    promise.catch((error) => {
      // when rejected store the error
      meta.status = "rejected";
      meta.error = error;
    });
    promises.set(key, meta);
  }

  if (meta.status === "pending") {
    // if still pending, throw promise to Suspense
    throw meta.promise;
  }

  if (meta.status === "error") {
    // if error, throw error to ErrorBoundary
    throw new Error(meta.error);
  }

  return meta.data as T | undefined; // otherwise, return resolved data
}
