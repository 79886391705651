/**
 * @type {import("./types").CountryId[]}
 */
const countries = ["kz", "kg", "hu", "ro"];

export const availableCountries = {
  codes: countries,
  set: new Set(countries),
  /**
   * @param {any} code
   * @returns {import("./types").CountryId}
   */
  parse(code) {
    if (code && this.set.has(code)) {
      return code;
    }
    throw new Error(`Invalid country code: ${code}`);
  },
  /**
   * @param {any} code
   * @returns {{data?: import("./types").CountryId}}
   */
  safeParse(code) {
    if (code && this.set.has(code)) {
      return { data: code };
    }
    return { data: undefined };
  },
};

/**
 * @type {import("./types").LocaleId[]}
 */
const locales = ["ru", "en", "kk", "hu", "ro"];

export const availableLocales = {
  codes: locales,
  set: new Set(locales),
  /**
   * @param {any} code
   * @returns {import("./types").LocaleId}
   */
  parse(code) {
    if (code && this.set.has(code)) {
      return code;
    }
    throw new Error(`Invalid locale code: ${code}`);
  },
  /**
   * @param {any} code
   * @returns {{data?: import("./types").LocaleId}}
   */
  safeParse(code) {
    if (code && this.set.has(code)) {
      return { data: code };
    }
    return { data: undefined };
  },
};

/**
 * @param {import("./types").CountryId} countryId
 * @returns {import("./types").BaseCountryInfo}
 */
export const resolveCountryInfo = (countryId) => {
  switch (countryId) {
    case "kz":
      return {
        countryId: "kz",
        currency: "KZT",
        defaultLocale: "ru",
        ogLocale: "ru_KZ",
        locales: ["ru", "kk"],
        brandName: "DOQ.kz",
        link: "https://doq.kz",
        phone: {
          code: "+7",
          city_code_length: 3,
          mask: "+7 (###) ###-##-##",
        },
      };
    case "kg":
      return {
        countryId: "kg",
        currency: "KGS",
        defaultLocale: "ru",
        ogLocale: "ru_KG",
        locales: ["ru"],
        brandName: "odoctor.kg",
        link: "https://odoctor.kg",
        phone: {
          code: "+996",
          city_code_length: 4,
          mask: "+996 ### ##-##-##",
        },
      };
    case "hu":
      return {
        countryId: "hu",
        currency: "HUF",
        defaultLocale: "hu",
        ogLocale: "hu_HU",
        locales: ["hu", "en"],
        brandName: "odoktor.hu",
        link: "https://odoktor.hu",
        phone: {
          code: "+36",
          city_code_length: 2,
          mask: "+36 (##) ###-####",
        },
      };
    case "ro":
      return {
        countryId: "ro",
        currency: "RON",
        defaultLocale: "ro",
        ogLocale: "ro_RO",
        locales: ["ro", "en"],
        brandName: "prodoctor.ro",
        link: "https://prodoctor.ro",
        phone: {
          code: "+40",
          city_code_length: 3,
          mask: "+40 (###) ### ###",
        },
      };
  }
};
