import {
  createContext,
  type ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

const ScreenTypeContext = createContext<{
  isMobile: boolean;
  isSmallMobile: boolean;
  isDesktop: boolean;
  isTablet: boolean;
  windowWidth: number;
} | null>(null);

export const ScreenTypeProvider = ({ children }: { children: ReactNode }) => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowSizeChange = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleWindowSizeChange);

    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, [setWidth]);

  const isMobile = width <= 1024;
  const isSmallMobile = width < 410;
  const isTablet = width >= 768;
  const isDesktop = width > 1024;

  return (
    <ScreenTypeContext.Provider
      value={{
        isMobile,
        isSmallMobile,
        isDesktop,
        isTablet,
        windowWidth: width,
      }}
    >
      {children}
    </ScreenTypeContext.Provider>
  );
};

export const useScreenDetector = () => {
  const context = useContext(ScreenTypeContext);

  if (!context) {
    throw new Error(
      "useScreenDetector must be used within a ScreenTypeProvider",
    );
  }
  return context;
};
