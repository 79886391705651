import { useMessage } from "@hermes/intl";
import type { TextFieldAria } from "react-aria";

import { cssFns } from "../../css";
import { useSecondaryColors } from "../../theme";

export const FieldError = ({
  errorMessageProps,
  errorMessage,
  fieldRequired,
}: {
  fieldRequired?: boolean;
  errorMessage?: string;
  errorMessageProps?: TextFieldAria["errorMessageProps"];
}) => {
  const secondaryColors = useSecondaryColors();
  const message = useMessage();

  return (
    <div
      css={[
        cssFns.typo({
          level: "body-3",
          weight: "regular",
        }),
        {
          color: secondaryColors.red,
        },
      ]}
      {...errorMessageProps}
    >
      {errorMessage
        ? errorMessage
        : fieldRequired &&
          message({ id: "common.required", default: "Это поле обязательное" })}
    </div>
  );
};
