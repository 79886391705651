import type { ReactNode } from "react";

import { polyfillDatetimeFormat } from "./datetime-format";
import { polyfillDisplayNames } from "./display-names";
import { polyfillListFormat } from "./list-format";
import { polyfillNumberFormat } from "./number-format";
import { polyfillPluralRules } from "./plural-rules";
import { polyfillRelativeTimeFormat } from "./relative-time-format";

const supportedLocales = new Set<string>();

export const PollyFillLoader = ({
  availableLocales,
  children,
}: {
  availableLocales: string[];
  children: ReactNode;
}) => {
  const unsupportedLocales = availableLocales.filter(
    (locale) => !supportedLocales.has(locale),
  );

  if (unsupportedLocales.length) {
    throw Promise.all([
      polyfillListFormat(unsupportedLocales),
      polyfillDisplayNames(unsupportedLocales),
      polyfillPluralRules(unsupportedLocales)
        .then(() => polyfillNumberFormat(unsupportedLocales))
        .then(() =>
          Promise.all([
            polyfillDatetimeFormat(unsupportedLocales),
            polyfillRelativeTimeFormat(unsupportedLocales),
          ]),
        ),
    ]).then(() => {
      unsupportedLocales.forEach((locale) => supportedLocales.add(locale));
    });
  }

  return <>{children}</>;
};
