import { HttpError } from "@hermes/api";
import { Temporal } from "@hermes/intl";
import { QueryClient } from "@tanstack/react-query";

export const makeDefaultQueryClient = () => {
  return new QueryClient({
    defaultOptions: {
      queries: {
        retry(count: number, error: unknown) {
          const status = error instanceof HttpError ? error.status : undefined;
          const isSuitableStatus = status !== undefined && status >= 500;

          return isSuitableStatus && count < 3;
        },
        gcTime: Temporal.Duration.from({ hours: 24 }).total("milliseconds"),
        staleTime: Infinity,
        refetchOnReconnect: false,
        refetchIntervalInBackground: false,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        throwOnError: false,
      },
    },
  });
};
