import type { SerializedStyles } from "@emotion/react";
import {
  type MouseEvent,
  type PointerEvent,
  type ReactNode,
  type RefObject,
  useRef,
} from "react";
import {
  type AriaButtonOptions,
  mergeProps,
  useButton,
  useHover,
} from "react-aria";

import { DesktopArrowDown, DesktopArrowUp } from "../icons";
import { type ButtonViewVariant, useButtonV2Styles } from "./styles";

export type ButtonV2Props = {
  triggerRef?: RefObject<HTMLButtonElement>;
  text: string;
  icon?: ReactNode;
  active?: boolean;
  disabled?: boolean;
  onPress?: () => void;
  onIconPress?: () => void;
  triggerProps?: AriaButtonOptions<"button">;
  type?: "button" | "submit";
  id?: string;
  form?: string;
  dataSelector?: string;
  styles?: SerializedStyles;
} & ButtonViewVariant;

export const ButtonV2 = (props: ButtonV2Props) => {
  const {
    triggerRef: outerRef,
    text,
    disabled,
    onPress,
    triggerProps,
    icon,
    onIconPress,
    variant,
    type,
    id,
    form,
    dataSelector,
    styles: overrideStyles,
  } = props;

  const innerRef = useRef<HTMLButtonElement>(null);
  const ref = outerRef || innerRef;

  const { hoverProps, isHovered } = useHover({});
  const { buttonProps, isPressed } = useButton(
    {
      ...triggerProps,
      onPress: (e) => {
        onPress?.();
        triggerProps?.onPress?.(e);
      },
      isDisabled: disabled,
      "aria-label": variant === "icon" ? text : undefined,
      type,
    },
    ref as RefObject<HTMLButtonElement>,
  );

  const isIconVariant = !!variant?.includes("icon");
  const dropDownIconState =
    "dropDownIconState" in props ? props.dropDownIconState : undefined;
  const defaultIcon =
    typeof dropDownIconState !== "undefined" ? (
      dropDownIconState ? (
        <DesktopArrowUp />
      ) : (
        <DesktopArrowDown />
      )
    ) : triggerProps?.["aria-expanded"] ? (
      <DesktopArrowUp />
    ) : (
      <DesktopArrowDown />
    );

  const styles = useButtonV2Styles({
    ...props,
    isHovered,
    isPressed,
    isIconVariant,
  });

  const handleIconClick = (e: MouseEvent | PointerEvent) => {
    if (onIconPress) {
      e.stopPropagation();
      onIconPress();
    }
  };

  return (
    <button
      ref={ref}
      css={[styles, overrideStyles]}
      data-selector={dataSelector ?? ""}
      id={id}
      form={form}
      {...mergeProps(buttonProps, hoverProps)}
    >
      {variant !== "icon" && <div>{text}</div>}
      {(icon || isIconVariant) && (
        <div
          css={{
            zIndex: 1,
            display: "flex",
          }}
          onClick={handleIconClick}
          onMouseDown={handleIconClick}
          onPointerDown={handleIconClick}
          onPointerUp={handleIconClick}
        >
          {icon ?? (isIconVariant && defaultIcon)}
        </div>
      )}
    </button>
  );
};
