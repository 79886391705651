import { useEffect, useRef, useState } from "react";

export const useDebouncedValue = <T>(value: T, delay: number): T => {
  const [debouncedState, setDebouncedState] = useState(value);
  useEffect(() => {
    const t = setTimeout(() => {
      setDebouncedState(value);
    }, delay);
    return () => {
      clearTimeout(t);
    };
  }, [value, delay]);

  return debouncedState;
};

export function usePreviousState<T>(state: T): T | undefined {
  const ref = useRef<T | undefined>(state);

  useEffect(() => {
    ref.current = state;
  }, [state]);

  return ref.current;
}
