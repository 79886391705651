import type { User } from "@hermes/api";
import {
  createContext,
  type ReactNode,
  useCallback,
  useMemo,
  useState,
} from "react";
import Cookies from "universal-cookie";

export type UserRole =
  | "clinic_admin"
  | "clinic_branch_admin"
  | "root"
  | "account_manager"
  | "copywriter"
  | "operator"
  | "operator_manager";

type AuthContextType = (
  | {
      user: User;
      role: UserRole;
    }
  | {
      user?: undefined;
      role?: undefined;
    }
) & {
  authenticate: (user: User) => void;
  signOut: () => void;
};

const cookies = new Cookies();

export const Context = createContext<AuthContextType | undefined>(undefined);

const getUserRole = (user?: User): UserRole | undefined => {
  if (!user) return undefined;
  if (user.is_superuser) return "root";
  if (user.is_clinic_administrator) return "clinic_admin";
  if (user.is_clinic_branch_administrator) return "clinic_branch_admin";
  if (user.is_account_manager) return "account_manager";
  if (user.is_copywriter) return "copywriter";
  if (user.is_operator) return "operator";
  if (user.is_operator_manager) return "operator_manager";
  return undefined;
};

export const getUserCookie = (): User | undefined => cookies.get("user");

export const AuthContext = ({
  children,
  cookieDomain,
}: {
  children: ReactNode;
  cookieDomain: string;
}) => {
  const [user, setUser] = useState<User | undefined>(getUserCookie());
  const role = useMemo(() => getUserRole(user), [user]);

  const authenticate = useCallback(
    (user: User) => {
      cookies.set("user", user, { domain: cookieDomain, path: "/" });
      setUser(user);
    },
    [cookieDomain],
  );
  const signOut = useCallback(() => {
    cookies.remove("user", { domain: cookieDomain, path: "/" });
    setUser(undefined);
  }, [cookieDomain]);

  return (
    <Context.Provider
      value={
        user && role
          ? {
              user,
              role,
              authenticate,
              signOut,
            }
          : {
              authenticate,
              signOut,
            }
      }
    >
      {children}
    </Context.Provider>
  );
};
