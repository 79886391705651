import { GTMProvider, useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import { type ReactNode } from "react";

export type GTMEcommerceImpression = {
  name: string;
  id: number;
  price: number;
  brand: string;
  category: string;
  variant: string;
  list: string;
  position?: number;
};

export type GTMEcommerceLayer =
  | {
      ecommerce: {
        currencyCode: string;
        impressions: GTMEcommerceImpression[];
      };
      event: "gtm-ee-event";
      "gtm-ee-event-category": string;
      "gtm-ee-event-action": "Product Impressions";
      "gtm-ee-event-non-interaction": string;
      "gtm.uniqueEventId": number;
    }
  | {
      ecommerce: {
        currencyCode: string;
        detail: {
          actionField: {
            list: string;
          };
          products: {
            name: string;
            id: number;
            price: number;
            brand: string;
            category: string;
            variant: string;
          }[];
        };
      };
      event: "gtm-ee-event";
      "gtm-ee-event-category": string;
      "gtm-ee-event-action": "Product Details";
      "gtm-ee-event-non-interaction": string;
      "gtm.uniqueEventId": number;
    };

export type GTMDataLayer =
  | {
      event: string;
      [key: string]: any;
    }
  | {
      event: "virtualPageview";
      pageUrl: string;
      pageTitle: string;
      [key: string]: any;
    }
  | GTMEcommerceLayer;

function getSessionData() {
  const pattern = new RegExp(
    `_ga_${import.meta.env.DOQ_GOOGLE_SESSION_ID}=GS\\d.\\d.(\\w+).(\\d+)`,
  );
  const match = document.cookie.match(pattern);

  const parts = match?.[0]?.split(".");

  if (!parts) {
    return;
  }

  return {
    ga_session_number: parts.pop(),
    ga_session_id: parts.pop(),
  };
}
(window as any).getSessionData = getSessionData;

function get_ga_client_id() {
  const cookie: Record<string, string> = {};
  document.cookie.split(";").forEach(function (el) {
    const [key, value] = el.split("=");
    if (key?.trim() && value) {
      cookie[key.trim()] = value;
    }
  });

  return cookie["_ga"] ? cookie["_ga"].substring(6) : undefined;
}
(window as any).get_ga_client_id = get_ga_client_id;

export const useGTM = () => {
  const dispatch = import.meta.env.DOQ_GTM_ID
    ? useGTMDispatch()
    : (data: object) => {
        // eslint-disable-next-line no-console
        console.debug("GTM dispatch", data);
      };

  return {
    sendGTMData: dispatch,
    getGAClientInfo() {
      return {
        ga_client_id: get_ga_client_id(),
        ...getSessionData(),
      };
    },
  };
};

export const GTMWrapper = ({ children }: { children: ReactNode }) => {
  if (import.meta.env.DOQ_GTM_ID) {
    return (
      <GTMProvider
        state={{ id: import.meta.env.DOQ_GTM_ID, injectScript: true }}
      >
        {children}
      </GTMProvider>
    );
  }

  return children;
};

export const sendVirtualPageView = (url: string, title: string) => {
  if (!window.dataLayer) {
    window.dataLayer = [];
  }

  const dataLayer = window.dataLayer as GTMDataLayer[];
  dataLayer.push({
    event: "virtualPageview",
    pageUrl: url,
    pageTitle: title,
  });
};
