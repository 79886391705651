import {
  useQuery,
  type UseQueryOptions,
  useSuspenseQuery,
  type UseSuspenseQueryOptions,
} from "@tanstack/react-query";

import { useApi } from "../../context";
import type { CityArea } from "./definitions";

type UseV0CityAreasParams = {
  city_pk: number;
};
export type UseV0CityAreasResult = CityArea[];

type UseV0CityAreasOptions = UseQueryOptions<UseV0CityAreasResult>;

export const useV0CityAreas = (
  params: UseV0CityAreasParams,
  options?: Partial<UseV0CityAreasOptions>,
) => {
  const url = `/api/v0/cities/${params.city_pk}/city_areas/`;
  const { api } = useApi();

  return useQuery({
    ...options,
    queryKey: [url, params],
    queryFn: () => {
      return api<UseV0CityAreasResult>(
        {
          url,
          method: "GET",
        },
        {
          200: async (r) => r.data,
        },
      );
    },
  });
};
type UseV0CityAreasSuspenseParams = {
  city_pk: number;
};
export type UseV0CityAreasSuspenseResult = CityArea[];

type UseV0CityAreasSuspenseOptions =
  UseSuspenseQueryOptions<UseV0CityAreasSuspenseResult>;

export const useV0CityAreasSuspense = (
  params: UseV0CityAreasSuspenseParams,
  options?: Partial<UseV0CityAreasSuspenseOptions>,
) => {
  const url = `/api/v0/cities/${params.city_pk}/city_areas/`;
  const { api } = useApi();

  return useSuspenseQuery({
    ...options,
    queryKey: [url, params],
    queryFn: () => {
      return api<UseV0CityAreasSuspenseResult>(
        {
          url,
          method: "GET",
        },
        {
          200: async (r) => r.data,
        },
      );
    },
  });
};

type UseV1CityAreasParams = {
  city: number;
  service?: number[];
};
export type UseV1CityAreasResult = {
  count: number;
  next: string | null;
  previous: string | null;
  results: CityArea[];
};

type UseV1CityAreasOptions = UseQueryOptions<UseV1CityAreasResult>;

export const useV1CityAreas = (
  params: UseV1CityAreasParams,
  options?: Partial<UseV1CityAreasOptions>,
) => {
  const url = `/api/v1/city-areas/`;
  const { api } = useApi();

  return useQuery({
    ...options,
    queryKey: [url, params],
    queryFn: () => {
      return api(
        {
          url,
          method: "GET",
          params: {
            ...params,
            service: params.service?.join(","),
          },
        },
        {
          200: async (r) => r.data,
        },
      );
    },
  });
};

type UseV1CityAreasSuspenseParams = UseV1CityAreasParams;
export type UseV1CityAreasSuspenseResult = {
  count: number;
  next: string | null;
  previous: string | null;
  results: CityArea[];
};

type UseV1CityAreasSuspenseOptions =
  UseSuspenseQueryOptions<UseV1CityAreasSuspenseResult>;

export const useV1CityAreasSuspense = (
  params: UseV1CityAreasSuspenseParams,
  options?: Partial<UseV1CityAreasSuspenseOptions>,
) => {
  const url = `/api/v1/city-areas/`;
  const { api } = useApi();

  return useSuspenseQuery({
    ...options,
    queryKey: [url, params],
    queryFn: () => {
      return api(
        {
          url,
          method: "GET",
          params: {
            ...params,
            service: params.service?.join(","),
          },
        },
        {
          200: async (r) => r.data,
        },
      );
    },
  });
};
