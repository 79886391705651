// import "@formatjs/intl-locale/polyfill";

declare global {
  namespace Intl {
    interface Locale extends LocaleOptions {
      getWeekInfo: () => {
        firstDay: 1 | 7;
        weekend: number[];
        minimalDays: number;
      };
    }
  }
}

if (!Intl.Locale.prototype["getWeekInfo"]) {
  Intl.Locale.prototype["getWeekInfo"] = function () {
    return this.weekInfo;
  };
}

export {};
