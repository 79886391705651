import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

if (import.meta.env.DOQ_ENVIRONMENT === "production") {
  Sentry.init({
    environment: import.meta.env.DOQ_ENVIRONMENT,
    dsn: import.meta.env.DOQ_SENTRY_API_KEY,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
    ],
    normalizeDepth: 10,
    tracesSampleRate: 0.05,
    tracePropagationTargets: [
      /^\//,
      new RegExp(`^${import.meta.env.DOQ_API_URL}`),
    ],
    replaysSessionSampleRate: 0.05,
    replaysOnErrorSampleRate: 0.05,
  });
}
