import { useSuspense } from "@hermes/ui";

export const capitalize = (str: string) => str[0]?.toUpperCase() + str.slice(1);

export type GeoPosition = {
  lat?: number;
  lng?: number;
};

const getCurrentPositionAsync = () => {
  return new Promise<
    | { status: true; data: GeoPosition }
    | { status: false; error: GeolocationPositionError }
  >((resolve) => {
    navigator.geolocation.getCurrentPosition(
      ({ coords }) => {
        resolve({
          status: true,
          data: {
            lat: coords.latitude,
            lng: coords.longitude,
          },
        });
      },
      (err) => {
        resolve({
          status: false,
          error: err,
        });
      },
    );
  });
};

export const useGeolocation = ({ enabled }: { enabled?: boolean }) => {
  const data = useSuspense(
    "position",
    () => getCurrentPositionAsync(),
    enabled,
  );

  return {
    position: data?.status === true ? data.data : undefined,
    error: data?.status === false ? data.error : undefined,
  };
};
