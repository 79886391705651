export {};

declare global {
  interface Array<T> {
    groupBy<K extends keyof any>(key: (x: T) => K): Record<K, T[]>;
  }
}

// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
if (!Array.prototype.groupBy) {
  Array.prototype.groupBy = function <T, K extends keyof any>(
    this: T[],
    key: (x: T) => K,
  ): Record<K, T[]> {
    return this.reduce(
      (groups, item) => {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        (groups[key(item)] ||= []).push(item);
        return groups;
      },
      {} as Record<K, T[]>,
    );
  };
}
