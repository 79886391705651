import * as localeMatcher from "@formatjs/intl-localematcher";
import {
  createContext,
  type ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { PollyFillLoader } from "./polyfill";

const Context = createContext<
  [Intl.Locale, (locale: Intl.Locale | string) => void, string[]] | undefined
>(undefined);

export const withNumberingSystem = (locale: Intl.Locale) =>
  new Intl.Locale(locale, { numberingSystem: "latn" });

export const LocaleProvider = ({
  value,
  children,
  availableLocales,
}: {
  availableLocales: string[];
  value: Intl.Locale;
  children: ReactNode;
}) => {
  const [locale, setLocale] = useState(withNumberingSystem(value));

  useEffect(() => {
    setLocale(withNumberingSystem(value));
  }, [value.baseName]);

  return (
    <Context.Provider
      value={[
        locale,
        useCallback((locale) => {
          setLocale(
            withNumberingSystem(
              typeof locale === "string" ? new Intl.Locale(locale) : locale,
            ),
          );
        }, []),
        availableLocales,
      ]}
    >
      <PollyFillLoader availableLocales={availableLocales}>
        {children}
      </PollyFillLoader>
    </Context.Provider>
  );
};

export const useLocale = () => {
  const locale = useContext(Context);

  if (!locale) {
    throw Error("useLocale requires LocaleProvider in the react tree");
  }

  return locale;
};

export const usePreferredLocales = () => {
  const makeLocales = () => {
    const languages = navigator.languages.length
      ? [...navigator.languages]
      : [navigator.language];

    return languages.map((language) => new Intl.Locale(language)) as [
      Intl.Locale,
      ...Intl.Locale[],
    ];
  };

  const [locales, setLocales] = useState(makeLocales);

  useEffect(() => {
    const listener = () => setLocales(makeLocales);
    window.addEventListener("languagechange", listener);
    return () => window.removeEventListener("languagechange", listener);
  }, []);

  return locales;
};

export const useNegotiatedLocale = ({
  requested,
  available,
  default: default_,
}: {
  requested: Intl.Locale | string | (Intl.Locale | string)[];
  available: Intl.Locale | string | (Intl.Locale | string)[];
  default: Intl.Locale | string;
}) => {
  const locale = useMemo(
    () =>
      localeMatcher.match(
        [requested].flat().map((locale) => locale.toString()),
        [available].flat().map((locale) => locale.toString()),
        default_.toString(),
      ),
    [requested, available, default_],
  );
  return useMemo(() => new Intl.Locale(locale), [locale]);
};
