import { Navigate, useParams } from "react-router-dom";

import { useRoutePath } from "#internal/app/router";

export const ServicesRedirectPage = () => {
  const { routePath } = useRoutePath();
  const params = useParams();

  const city = params["city"];
  const slug = params["*"];

  if (!city) {
    return <Navigate to={routePath({ path: "/404" })} replace />;
  } else if (slug) {
    if (slug.includes("categories")) {
      const parts = slug.split("/");

      return (
        <Navigate
          to={routePath({
            path: "/procedures/:city/categories",
            params: {
              city,
            },
            query: {
              category_id: parts[1],
            },
          })}
        />
      );
    }
    return (
      <Navigate
        to={routePath({
          path: "/doctors/:city/:service",
          params: {
            city,
            service: slug,
          },
        })}
      />
    );
  }

  return (
    <Navigate
      to={routePath({
        path: "/procedures/:city",
        params: {
          city,
        },
      })}
    />
  );
};
