import { HttpError } from "@hermes/api";
import { ButtonV2, CenteredSpinner, cssFns } from "@hermes/ui";
import { QueryErrorResetBoundary } from "@tanstack/react-query";
import { type ReactNode, useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";

type ErrorBoundaryProps = {
  error: Error;
  onReset: () => void;
};

export type ErrorBoundaryComponent = (props: ErrorBoundaryProps) => ReactNode;

const DefaultErrorBoundaryComponent: ErrorBoundaryComponent = ({
  error,
  onReset,
}) => {
  return (
    <div css={[cssFns.center(), { minHeight: "100vh" }]}>
      <div css={{ display: "flex", flexDirection: "column", gap: "16px" }}>
        <span css={cssFns.typo({ level: "title-4" })}>Что-то пошло не так</span>
        <span css={cssFns.typo({ level: "body-2" })}>
          Ошибка: {error.message}
        </span>
        <ButtonV2
          variant="solid"
          text="Попробовать еще раз"
          onPress={onReset}
        />
        <ButtonV2
          variant="solid"
          text="На главную"
          onPress={() => {
            window.location.href = "/";
          }}
        />
      </div>
    </div>
  );
};

const ErrorBoundaryConditions = ({
  children,
  error,
  resetCount,
  onReset,
}: {
  children: ReactNode;
  resetCount: number;
} & ErrorBoundaryProps) => {
  const shouldAutoReset =
    resetCount < 3 && error instanceof HttpError && error.ignore;

  useEffect(() => {
    if (shouldAutoReset) {
      onReset();
    }
  }, [shouldAutoReset]);

  if (shouldAutoReset) {
    return <CenteredSpinner />;
  }

  return children;
};

export const AppErrorBoundary = ({
  children,
  errorBoundaryComponent: Content,
}: {
  children: ReactNode;
  errorBoundaryComponent?: ErrorBoundaryComponent;
}) => {
  const [count, setCount] = useState(0);

  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <ErrorBoundary
          onReset={reset}
          fallbackRender={({ error, resetErrorBoundary }) => {
            return (
              <ErrorBoundaryConditions
                error={error}
                resetCount={count}
                onReset={() => {
                  resetErrorBoundary();
                  setCount((c) => c + 1);
                }}
              >
                {Content ? (
                  <Content error={error} onReset={resetErrorBoundary} />
                ) : (
                  <DefaultErrorBoundaryComponent
                    error={error}
                    onReset={resetErrorBoundary}
                  />
                )}
              </ErrorBoundaryConditions>
            );
          }}
        >
          {children}
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
};
