import { cssFns, usePrincipalColors } from "@hermes/ui";
import { NavLink, type NavLinkProps } from "react-router-dom";

export const StyledNavLink = (props: NavLinkProps) => {
  const principalColors = usePrincipalColors();
  return (
    <NavLink
      {...props}
      css={[
        { color: principalColors.white, textDecoration: "none" },
        cssFns.typo({ level: "body-1", weight: "regular" }),
      ]}
    />
  );
};
