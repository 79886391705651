import { useNow } from "@hermes/intl";
import { cssFns, usePrincipalColors } from "@hermes/ui";

import { useAppConfig } from "#internal/app/config";

export const Copyright = () => {
  const { country } = useAppConfig();
  const principalColors = usePrincipalColors();

  return (
    <p
      css={[
        cssFns.typo({ level: "body-1", weight: "regular" }),
        { color: principalColors.white, opacity: 0.5, margin: 0 },
      ]}
    >
      © {useNow("year").year} {country.brandName}, Inc. All Rights Reserved
    </p>
  );
};
