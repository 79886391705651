import { css } from "@emotion/react";

import type { SizeUnit } from "../utils";

export const gridRow = (start: SizeUnit, end: SizeUnit) => {
  return css({
    gridRowStart: start,
    gridRowEnd: end,
  });
};

export const gridColumn = (start: SizeUnit, end: SizeUnit) => {
  return css({
    gridColumnStart: start,
    gridColumnEnd: end,
  });
};
