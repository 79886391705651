type StringLike = string | number | boolean | undefined;

export const getPartialRoute = (route: {
  path: string;
  params?: Record<string, StringLike>;
  query?: Record<string, StringLike>;
}) => {
  const search = new URLSearchParams({});

  let url: string = route.path;

  if ("query" in route && route.query) {
    for (const [key, value] of Object.entries(route.query)) {
      if (value) {
        search.append(key, value.toString());
      }
    }
  }

  if ("params" in route && route.params) {
    for (const [key, value] of Object.entries(route.params)) {
      url = url.replace(`:${key}`, value?.toString() || "");
    }

    url = url.replace(/\/:[^/]+\?/g, "");
  }

  return {
    pathname: url,
    search:
      [...new Set(search.keys())].length > 0
        ? `?${search.toString()}`
        : undefined,
  };
};
