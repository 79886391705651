import { css, type SerializedStyles } from "@emotion/react";

import type { SizeUnit } from "../utils";

export const inset: {
  (all: SizeUnit): SerializedStyles;
  (block: SizeUnit, inline: SizeUnit): SerializedStyles;
} = (a: SizeUnit, b?: SizeUnit): SerializedStyles => {
  return css({
    insetBlockStart: a,
    insetBlockEnd: a,
    insetInlineStart: b ?? a,
    insetInlineEnd: b ?? a,
  });
};
