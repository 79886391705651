import type { ReactNode } from "react";

export type OverlayContentOptions = { close: () => void };
export type Content<Options> = ReactNode | ((options: Options) => ReactNode);

export function renderContent<Options>(
  content: Content<Options>,
  options: Options,
) {
  if (typeof content === "function") {
    return content(options);
  }

  return content;
}
