import { createContext, useContext as useReactContext } from "react";

export function makeOptions<T>(name: string, defaultOptions?: T) {
  const Context = createContext<T | null>(defaultOptions ?? null);

  const useContext = () => {
    const context = useReactContext(Context);

    if (!context) {
      throw new Error(`useOptions must be wrapped in ${name} options Provider`);
    }

    return context;
  };

  return { OptionsProvider: Context.Provider, useOptions: useContext };
}
