import { shouldPolyfill } from "@formatjs/intl-numberformat/should-polyfill";

const data = {
  en: async () => import("@formatjs/intl-numberformat/locale-data/en"),
  hu: async () => import("@formatjs/intl-numberformat/locale-data/hu"),
  kk: async () => import("@formatjs/intl-numberformat/locale-data/kk"),
  ro: async () => import("@formatjs/intl-numberformat/locale-data/ro"),
  ru: async () => import("@formatjs/intl-numberformat/locale-data/ru"),
} as Record<string, Function>;

export async function polyfillNumberFormat(locales: string[]) {
  const localesToPolyfill = locales.map((locale) => shouldPolyfill(locale));

  if (localesToPolyfill.some(Boolean)) {
    await import("@formatjs/intl-numberformat/polyfill-force");
  }

  await Promise.all(
    localesToPolyfill.map((locale) => {
      if (!locale) return null;
      const localeData = data[locale];

      return localeData?.();
    }),
  );
}
