import { css, type SerializedStyles } from "@emotion/react";

export const boxShadow = (
  ...boxShadows: (
    | {
        inset?: boolean;
        offsetX?: string;
        offsetY?: string;
        blurRadius?: string;
        spreadRadius?: string;
        color?: string;
      }
    | false
    | null
    | undefined
  )[]
): SerializedStyles => {
  const boxShadow = boxShadows
    .map((boxShadow) => {
      if (!boxShadow) {
        return undefined;
      }

      const {
        offsetX = "0px",
        offsetY = "0px",
        blurRadius = "0px",
        spreadRadius = "0px",
        color = "currentColor",
      } = boxShadow;

      const inset = boxShadow.inset ? "inset " : "";

      return `${inset} ${offsetX} ${offsetY} ${blurRadius} ${spreadRadius} ${color}`;
    })
    .filter(Boolean)
    .join(", ");

  return css({
    boxShadow,
  });
};
