import { useLocale } from "@hermes/intl";
import { getPartialRoute } from "@hermes/shared";
import { useCallback } from "react";
import type { To } from "react-router-dom";

import { useAppConfig } from "../config";
import type { ExactRoute } from "./types";

export const useRoutePath = () => {
  const { country } = useAppConfig();
  const [locale] = useLocale();

  return {
    routePath: useCallback(
      (
        route: ExactRoute & {
          hash?: string;
        },
      ): To => {
        const { pathname, search } = getPartialRoute(route);

        return {
          pathname:
            locale.baseName === country.defaultLocale
              ? pathname
              : "/" + locale.baseName + pathname,
          search,
          hash: route.hash,
        };
      },
      [locale.baseName, country.defaultLocale],
    ),
  };
};
