import { css } from "@emotion/react";
import {
  HermesAppContainer,
  makeDefaultAxiosInstance,
  makeDefaultQueryClient,
} from "@hermes/shared";
import {
  cssFns,
  Spinner,
  usePrincipalColors,
  useScreenDetector,
} from "@hermes/ui";
import { type ReactNode, Suspense, useEffect } from "react";
import {
  Outlet,
  ScrollRestoration,
  useMatches,
  useNavigation,
} from "react-router-dom";
import { Slide, ToastContainer } from "react-toastify";

import { Footer } from "#internal/features/footer";
import { Header } from "#internal/features/header";
import { SubHeader } from "#internal/features/header/sub-header";
import { CitySyncWrapper } from "#internal/features/search-bar/city-select";
import { AppHelmet } from "#internal/helmet";
import { messageCache } from "#internal/intl";
import { BtnScrollTop } from "#internal/shared/ui/btn-scroll-top";

import { AppConfigContext } from "./config";
import { Flags } from "./flags";
import { GTMWrapper } from "./gtm";

const ContentWrapper = ({ children }: { children: ReactNode }) => {
  const { isDesktop } = useScreenDetector();
  const principalColors = usePrincipalColors();
  const matches = useMatches();
  const { state: navigationState } = useNavigation();
  const outletRoute = matches[1];

  const isHomeRoute = outletRoute?.id === "home";

  const headerHeight = isDesktop ? "80px" : "50px";

  const loadingFallback = (
    <div
      css={[
        cssFns.center(),
        {
          flexGrow: 1,
          minHeight: `calc(100vh - ${headerHeight})`,
          paddingBlockEnd: headerHeight,
        },
      ]}
    >
      <Spinner />
    </div>
  );

  return (
    <div
      css={{
        color: principalColors.gs2,
        backgroundColor: principalColors.gs10,
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <ToastContainer
        autoClose={4000}
        hideProgressBar
        transition={Slide}
        position="top-right"
        closeOnClick
        pauseOnHover
        draggable
        theme="colored"
      />
      <AppHelmet />
      <Header
        variant={isHomeRoute && isDesktop ? "transparent" : undefined}
        height={headerHeight}
      />
      {isDesktop && !isHomeRoute && <SubHeader />}
      <div css={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
        <Suspense fallback={loadingFallback}>
          <CitySyncWrapper>{children}</CitySyncWrapper>
          {navigationState === "loading" && loadingFallback}
        </Suspense>
      </div>
      <Footer />
      <BtnScrollTop />
    </div>
  );
};

const queryClient = makeDefaultQueryClient();
const axiosInstance = makeDefaultAxiosInstance({
  baseURL: import.meta.env.DOQ_DEBUG_API_URL || import.meta.env.DOQ_API_URL,
});
const SCROLL_POSITIONS_KEY = "scroll-positions";

export const AppContainer = () => {
  useEffect(() => {
    // Reset scroll positions on page refresh
    sessionStorage.removeItem(SCROLL_POSITIONS_KEY);
  }, []);

  return (
    <HermesAppContainer
      axiosInstance={axiosInstance}
      queryClient={queryClient}
      countryId={import.meta.env.DOQ_COUNTRY_ID}
      cookieDomain={
        Boolean(import.meta.env.DOQ_LOCAL_COOKIE)
          ? "localhost"
          : import.meta.env.DOQ_COOKIE_DOMAIN
      }
      autoSendToken={false}
      messageCache={messageCache}
      disableQueryDevTools
      globalStyles={css([
        {
          "#root .react-loading-skeleton": {
            lineHeight: "24px",
          },
        },
      ])}
      storedLocaleKey="locale"
    >
      <GTMWrapper>
        <ScrollRestoration storageKey={SCROLL_POSITIONS_KEY} />
        <AppConfigContext>
          <Flags>
            <ContentWrapper>
              <Outlet />
            </ContentWrapper>
          </Flags>
        </AppConfigContext>
      </GTMWrapper>
    </HermesAppContainer>
  );
};
