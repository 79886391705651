import "./reset.css";

import { css, type Interpolation, type Theme } from "@emotion/react";

import {
  border,
  borderBlockEnd,
  borderBlockStart,
  borderInlineEnd,
  borderInlineStart,
} from "./border";
import { linearGradient, rgba, setOpacity } from "./colors";
import { gridColumn, gridRow } from "./grid";
import { inset } from "./position";
import { boxShadow } from "./shadow";
import {
  margin,
  marginBlock,
  marginInline,
  padding,
  paddingBlock,
  paddingInline,
} from "./spacing";
import { typo } from "./typo";

export type CSSProp = Interpolation<Theme>;

export const cssFns = {
  center: () =>
    css({
      display: "grid",
      placeItems: "center",
    }),
  margin,
  marginBlock,
  marginInline,
  padding,
  paddingBlock,
  paddingInline,
  typo,
  rgba,
  setOpacity,
  linearGradient,
  border,
  borderBlockEnd,
  borderBlockStart,
  borderInlineEnd,
  borderInlineStart,
  gridColumn,
  gridRow,
  inset,
  boxShadow,
};
