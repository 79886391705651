import { type RefObject, useRef } from "react";
import { type AriaMenuOptions, FocusScope, useMenu } from "react-aria";

import { cssFns } from "../css";
import { MenuItem } from "./item";
import { type MenuOptions, OptionsProvider } from "./options";
import { MenuSection } from "./section";

export type { MenuOptions };

export function Menu<Option>({
  menuRef: outerRef,
  menuProps: baseMenuProps,
  isSubmenu,
  options,
}: {
  menuRef?: RefObject<HTMLUListElement>;
  menuProps: AriaMenuOptions<{}>;
  isSubmenu?: boolean;
  options: Omit<MenuOptions<Option>, "parentMenuRef">;
}) {
  const innerRef = useRef<HTMLUListElement>(null);
  const ref = outerRef ?? innerRef;

  const { menuProps } = useMenu(
    { ...baseMenuProps, items: options.state.collection },
    options.state,
    ref,
  );

  return (
    <OptionsProvider
      value={
        {
          ...options,
          parentMenuRef: ref,
        } as MenuOptions<unknown>
      }
    >
      <FocusScope>
        <ul
          {...menuProps}
          ref={ref}
          css={[
            cssFns.margin("0px"),
            cssFns.padding("0px"),
            cssFns.border({ width: "0px", style: "none" }),
            {
              listStyleType: "none",
              outlineStyle: "none",
            },
          ]}
          onKeyDownCapture={() => {
            if (isSubmenu) return;

            options.onClose?.();
          }}
        >
          {[...options.state.collection].map((item) => {
            return item.type === "section" ? (
              <MenuSection key={item.key} section={item} />
            ) : (
              <MenuItem key={item.key} item={item} />
            );
          })}
        </ul>
      </FocusScope>
    </OptionsProvider>
  );
}
