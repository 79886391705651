import { useContext } from "react";

import { Context } from "./context";

export { AppConfigContext } from "./provider";

export const useAppConfig = () => {
  const config = useContext(Context);

  if (!config) {
    throw new Error("useAppConfig requires AppConfigContext in the react tree");
  }

  return config;
};
