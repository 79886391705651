import { keyframes, type SerializedStyles } from "@emotion/react";

import { cssFns } from "./css";
import { usePrincipalColors } from "./theme";

export const Spinner = ({
  styles,
  size = 40,
  color,
}: {
  styles?: SerializedStyles;
  size?: number;
  color?: string;
}) => {
  const principalColors = usePrincipalColors();

  return (
    <div
      css={[
        {
          width: size,
          height: size,
          border: `${size / 8}px solid ${color || principalColors.ebblue}`,
          borderTop: `${size / 8}px solid transparent`,
          borderRadius: "50%",
          animation: `${spinAnimation} 0.8s linear infinite`,
        },
        styles,
      ]}
    />
  );
};

const spinAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const CenteredSpinner = () => {
  return (
    <div css={[cssFns.center(), { minHeight: "100vh" }]}>
      <Spinner />
    </div>
  );
};
