import { cssFns, usePrincipalColors } from "@hermes/ui";

import { useAppConfig } from "#internal/app/config";

export const AlternativeLinks = () => {
  const { availableCountriesInfo, country } = useAppConfig();
  const principalColors = usePrincipalColors();

  return (
    <div css={{ display: "flex", gap: 16 }}>
      {availableCountriesInfo.map(({ link, name, countryId: id }, index) => (
        <a
          key={index}
          href={country.countryId === id && link ? "undefined" : link}
          target="_blank"
          css={[
            cssFns.typo({ level: "body-1", weight: "regular" }),
            {
              color: principalColors.white,
              opacity: country.countryId === id ? 0.5 : 1,
              textDecoration: "none",
              pointerEvents: country.countryId === id ? "none" : "auto",
            },
          ]}
        >
          {name}
        </a>
      ))}
    </div>
  );
};
