import { useContext } from "react";

import { Context } from "./auth-context";

export const useAuth = () => {
  const context = useContext(Context);
  if (!context) {
    throw new Error("useAuth must be called within AuthProvider");
  }
  return context;
};
