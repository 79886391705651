import { useAppConfig } from "#internal/app/config";
import fbIcon from "#internal/shared/assets/images/fb_icon_blue.svg";
import instagramIcon from "#internal/shared/assets/images/instagram_icon_blue.svg";

export const SideMenuFooter = () => {
  const { socialMediaLinks } = useAppConfig();

  return (
    socialMediaLinks && (
      <div
        css={{
          display: "flex",
          gap: "20px",
          marginTop: "auto",
          marginBottom: 10,
        }}
      >
        {socialMediaLinks.instagram && (
          <a href={socialMediaLinks.instagram} target="_blank">
            <img src={instagramIcon} alt="instagram icon" />
          </a>
        )}
        {socialMediaLinks.facebook && (
          <a href={socialMediaLinks.facebook} target="_blank">
            <img src={fbIcon} alt="fb icon" />
          </a>
        )}
      </div>
    )
  );
};
