import { cssFns, usePrincipalColors } from "@hermes/ui";
import { NavLink, type To } from "react-router-dom";

type FooterLinkProps = {
  name: string;
  icon?: string;
  to: To;
};

export const FooterLink = ({ name, icon, to }: FooterLinkProps) => {
  const principalColors = usePrincipalColors();
  return (
    <NavLink
      css={[
        {
          textDecoration: "none",
          color: principalColors.white,
          display: "flex",
          gap: 8,
          alignItems: "center",
        },
        cssFns.typo({ level: "body-1", weight: icon ? "semibold" : "regular" }),
      ]}
      to={to}
    >
      {icon && <img src={icon} alt="arrow-right-icon" />}
      {name}
    </NavLink>
  );
};
