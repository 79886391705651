import { css } from "@emotion/react";
import { useV1Services } from "@hermes/api";
import { Message, useLocale, useMessage } from "@hermes/intl";
import {
  type ComboboxImperativeRef,
  cssFns,
  icons,
  type SelectImperativeRef,
  TriggerButton,
  truthy,
  useComboboxState,
  useFormField,
  usePrincipalColors,
  useScreenDetector,
  useSecondaryColors,
} from "@hermes/ui";
import { useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useAppConfig } from "#internal/app/config";

import { CitySelect } from "./city-select";
import {
  type PartialHit,
  SearchBarSelect,
  useSearchBarRoute,
} from "./search-bar-select";

export const SearchBar = ({
  variant = "multiple",
  searchLabel,
}: {
  variant?: "single" | "multiple";
  searchLabel?: string;
}) => {
  const { city, cityArea } = useAppConfig();
  const navigate = useNavigate();
  const principalColors = usePrincipalColors();
  const secondaryColors = useSecondaryColors();
  const message = useMessage();
  const { isMobile } = useScreenDetector();
  const params = useParams();
  const slug = params["*"] || params["slug"] || null;

  const searchBarRoute = useSearchBarRoute();
  const searchRef = useRef<ComboboxImperativeRef>(null);

  const searchPlaceholder = message({
    id: "55adaf1a-d42b-4c8a-bc45-15f70c742807",
    default: "Врач, клиника или процедура",
  });

  const cityRef = useRef<SelectImperativeRef>(null);

  const [isOverlayOpen, setIsOverlayOpen] = useState(false);

  const { data: servicesData, isLoading: areServicesLoading } = useV1Services(
    {
      city: city.id,
      city_area: cityArea?.id,
      type: "initial-appointment",
      is_primary: true,
      has_adult_service: false,
    },
    { enabled: isOverlayOpen },
  );
  const services = servicesData?.results;
  const [locale] = useLocale();

  const searchField = useFormField(
    useComboboxState<PartialHit>({
      value: services?.find((service) => service.slug === slug)
        ? {
            key: {
              slug:
                services.find((service) => service.slug === slug)?.slug || "",
              type: "Специальности",
              objectID:
                services
                  .find((service) => service.slug === slug)
                  ?.id.toString() || "",
              title:
                services.find((service) => service.slug === slug)?.name || "",
            },
            label:
              services.find((service) => service.slug === slug)?.name || "",
          }
        : null,
    }),
  );

  const fallbackOptions =
    services
      ?.map((service) => ({
        key: {
          slug: service.slug,
          type: "Специальности" as const,
          objectID: service.id.toString(),
          title: service.name,
        },
        label: service.name,
      }))
      .filter((option) => {
        if (searchField.inputValue) {
          return option.label
            .toLowerCase()
            .includes(searchField.inputValue.toLowerCase());
        }

        return true;
      }) || [];

  return variant === "multiple" ? (
    <div
      css={[
        {
          display: "grid",
          width: "100%",
        },
        isMobile
          ? {
              gap: 10,
              maxWidth: "100%",
            }
          : {
              gridTemplateColumns: "1.25fr 1fr auto",
              gap: 2,
              maxWidth: "740px",
            },
      ]}
    >
      <SearchBarSelect
        field={searchField}
        searchRef={searchRef}
        onCityLinkClick={() => {
          cityRef.current?.open();
        }}
        placeholder={searchPlaceholder}
        areServicesLoading={areServicesLoading}
        onFocus={() => setIsOverlayOpen(true)}
        fallbackOptions={fallbackOptions}
      />
      <CitySelect cityRef={cityRef} />

      <TriggerButton
        onPress={() => {
          if (isMobile && searchField.value) {
            navigate(searchBarRoute(searchField.value.key));
            return;
          }
          searchRef.current?.open();
        }}
        styles={css([
          cssFns.typo({
            level: "body-2",
            color: principalColors.white,
            weight: "semibold",
          }),
          cssFns.border({ radius: "4px" }),
          {
            backgroundColor: secondaryColors.magenta,
            width: isMobile ? "100%" : "40px",
            height: isMobile ? "46px" : "40px",
            display: "grid",
            placeItems: "center",
            color: principalColors.white,
          },
        ])}
      >
        {isMobile ? (
          <Message id="f99ac49b-b9ad-40c1-94fb-89c126d5d0b0" default="Найти" />
        ) : (
          <icons.MobileMenuSearch />
        )}
      </TriggerButton>
    </div>
  ) : (
    <div>
      <SearchBarSelect
        field={searchField}
        searchRef={searchRef}
        onCityLinkClick={() => {
          cityRef.current?.open();
        }}
        placeholder={searchPlaceholder}
        onFocus={() => setIsOverlayOpen(true)}
        fallbackOptions={fallbackOptions}
      >
        {(inputRef, inputProps, buttonRef, buttonProps) => (
          <TriggerButton
            ref={buttonRef}
            triggerProps={buttonProps}
            styles={css([
              cssFns.padding("8px", "6px"),
              cssFns.border({ radius: "4px" }),
              {
                backgroundColor: secondaryColors.darkblue,
                display: "flex",
                alignItems: "center",
                gap: 6,
                width: "100%",
              },
            ])}
          >
            <input ref={inputRef} {...inputProps} css={{ display: "none" }} />
            <icons.MobileMenuSearch color={principalColors.white} />
            <div css={{ textAlign: "start" }}>
              <div
                css={[
                  cssFns.typo({ level: "body-1", weight: "semibold" }),
                  { color: principalColors.white, marginBlockEnd: "4px" },
                ]}
              >
                {searchLabel ||
                  (searchField.value?.key.title &&
                    (typeof searchField.value.key.title === "string"
                      ? searchField.value.key.title
                      : searchField.value.key.title[locale.baseName])) ||
                  searchPlaceholder}
              </div>
              <div
                css={[
                  cssFns.typo({ level: "body-3", weight: "regular" }),
                  { color: principalColors.grayline },
                ]}
              >
                {[city.name, cityArea?.name].filter(truthy).join(", ")}
              </div>
            </div>
          </TriggerButton>
        )}
      </SearchBarSelect>
      <CitySelect cityRef={cityRef}>
        {(triggerRef, triggerProps) => (
          <TriggerButton ref={triggerRef} triggerProps={triggerProps} />
        )}
      </CitySelect>
    </div>
  );
};
