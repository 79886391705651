import {
  createContext,
  type ReactNode,
  type SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useId } from "react-aria";

import { usePreviousState } from "../../state";
import { truthy } from "../../utils";

export type ValidationFn<T> = (value: T) => boolean | string;

export type FieldState<T = any> = {
  value: T;
  error?: {
    message?: string;
    visible: boolean;
  };
  required?: boolean;
  disabled?: boolean;
  rules?: ValidationFn<T>[];
  autoValidate?: boolean;
  validateInitially?: boolean;
  isDirty?: boolean;
  showValidationStatus?: boolean;
  // Change value if parent changes
  parent?: T;
};

export function useBaseFieldState<BaseFieldState extends FieldState>(
  defaultState: BaseFieldState,
): FieldStateTuple<BaseFieldState> {
  const [state, setState] = useState<BaseFieldState>({
    autoValidate: true,
    showValidationStatus:
      defaultState.showValidationStatus ??
      (defaultState.required || defaultState.rules?.length) ??
      false,
    ...defaultState,
  });

  useEffect(() => {
    setState((state) => ({
      ...state,
      error: defaultState.error,
    }));
  }, [defaultState.error]);

  useEffect(() => {
    if (typeof defaultState.parent === "undefined") return;
    setState((state) => ({
      ...state,
      value: defaultState.parent,
      parent: defaultState.parent,
    }));
  }, [defaultState.parent]);

  return [state, setState];
}

const context = createContext<{
  id: string;
} | null>(null);

export type FormFields<
  T extends {
    [key: string]: Field<FieldState>;
  },
> = T & { validate: () => boolean };

export const Form = <
  T extends {
    [key: string]: Field<FieldState>;
  },
>({
  id: outsideId,
  children,
  onSubmit,
  fields,
}: {
  id?: string;
  children?: ReactNode;
  onSubmit?: (e: React.FormEvent<HTMLFormElement>) => void;
  fields?: FormFields<T> | FormFields<T>[];
}) => {
  const innerId = useId();
  const id = outsideId ?? innerId;

  const groupedFields = (Array.isArray(fields) ? [...fields] : [fields]).filter(
    truthy,
  );

  return (
    <context.Provider
      value={{
        id,
      }}
    >
      <form
        id={id}
        onSubmit={(e) => {
          e.preventDefault();

          if (!groupedFields.length) return onSubmit?.(e);
          const results = groupedFields.map((fields) => fields.validate());
          if (results.every((result) => result)) return onSubmit?.(e);
        }}
      >
        {children}
      </form>
    </context.Provider>
  );
};

export const useForm = () => {
  return useContext(context);
};

export type FieldStateTuple<State extends FieldState = FieldState> = [
  state: State,
  React.Dispatch<React.SetStateAction<State>>,
];

export type Field<State extends FieldState> = FieldStateTuple<State>[0] & {
  onChange: FieldStateTuple<State>[1];
  validate?: BuiltValidateFn<FieldStateTuple<State>[0]>;
};

type BuiltValidateFn<T> = (
  value: T,
) => [boolean, { visible: boolean; message?: string }];

const useTupleToField = <Tuple extends FieldStateTuple>([
  state,
  onChange,
]: Tuple): Field<FieldStateTuple[0]> => {
  const previousValue = usePreviousState(state.value);

  const validate: BuiltValidateFn<FieldStateTuple[0]> = (value) => {
    if (state.rules) {
      for (const rule of state.rules) {
        const errorMessage = rule(value);

        if (typeof errorMessage === "string" || !errorMessage) {
          return [
            false,
            {
              message:
                typeof errorMessage === "string" ? errorMessage : undefined,
              visible: true,
            },
          ];
        }
      }
    }

    if (state.required && !truthy(value)) {
      return [false, { message: undefined, visible: true }];
    }

    return [
      true,
      {
        message: undefined,
        visible: false,
      },
    ];
  };

  useEffect(() => {
    if (state.value === previousValue && !state.isDirty) return;

    onChange((state) => ({
      ...state,
      isDirty: true,
    }));
  }, [state.value, state.isDirty, previousValue]);

  useEffect(() => {
    if (!state.autoValidate) return;
    if (
      state.value === previousValue &&
      !state.validateInitially &&
      !state.isDirty
    )
      return;
    const [result, error] = validate(state.value);

    onChange((state) => ({
      ...state,
      ...(result
        ? { error }
        : {
            error: undefined,
          }),
      isDirty: true,
    }));
  }, [
    state.value,
    state.autoValidate,
    state.isDirty,
    previousValue,
    state.validateInitially,
  ]);

  return {
    ...state,
    onChange,
    validate,
  };
};

export const useFormField = <T extends FieldStateTuple>(
  tuple: T,
): Field<T[0]> => {
  return useTupleToField(tuple);
};

export const useFormFields = <
  T extends {
    [key: string]: FieldStateTuple;
  },
>(
  fieldsTuples: T | (() => T),
) => {
  const definition =
    typeof fieldsTuples === "function" ? fieldsTuples() : fieldsTuples;
  const fields = Object.fromEntries(
    Object.entries(definition).map(([key, tuple]) => {
      return [key, useTupleToField(tuple)];
    }),
  ) as {
    [Property in keyof T]: Field<T[Property][0]>;
  };

  return {
    ...fields,
    validate: () => {
      return Object.entries(fields as Record<string, Field<FieldState>>).reduce(
        (flag, [_, field]) => {
          if (!field.validate) return flag;
          const [result, error] = field.validate(field.value);

          field.onChange((field) => ({
            ...field,
            error,
          }));

          return result && flag;
        },
        true,
      );
    },
  } as FormFields<typeof fields>;
};

export const useErrors = () => {
  const [errors, setErrors] = useState<Record<string, string[]>>({});

  const storage = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(errors).map(
          ([key, errors]) =>
            [
              key,
              {
                message: errors.join(", "),
                visible: true,
              },
            ] as const,
        ),
      ),
    [errors],
  );

  return {
    get: (key: string) => {
      return storage[key];
    },
    update: (
      entries: {
        key: string;
        message: string;
      }[],
    ) => {
      const groups = Object.fromEntries(
        Object.entries(entries.groupBy((entry) => entry.key)).map(
          ([key, messages]) => [key, messages.map(({ message }) => message)],
        ),
      );

      setErrors(groups);
    },
  };
};

export function fieldState<T extends FieldState>(
  field: Field<T>,
  state: SetStateAction<T>,
) {
  return "value" in state ? state : state(field);
}
