import {
  ButtonV2,
  cssFns,
  SideMenu,
  usePrincipalColors,
  useScreenDetector,
  useSecondaryColors,
  useSideMenuState,
} from "@hermes/ui";
import { useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import { Link, useMatches } from "react-router-dom";

import { useAppConfig } from "#internal/app/config";
import { RoutesIdEnum, useRoutePath } from "#internal/app/router";
import { mobileSearchableRoutes } from "#internal/app/router/router";
import { MobileMenuBurger } from "#internal/shared/ui/icons";
import { LanguageButton } from "#internal/shared/ui/language-button";
import { LoginButton } from "#internal/shared/ui/login-button";

import { SearchBar } from "../search-bar";
import { SideMenuFooter } from "./side-menu-content/footer";
import { SideMenuMainContent } from "./side-menu-content/main";

export const Header = ({
  variant,
  height,
}: {
  variant?: "transparent";
  height?: string;
}) => {
  const { routePath } = useRoutePath();
  const { isMobile, isDesktop } = useScreenDetector();
  const principalColors = usePrincipalColors();
  const secondaryColors = useSecondaryColors();
  const menuState = useSideMenuState({});

  const matches = useMatches();
  const outletRoute = matches[matches.length - 1];

  const { searchLabel, changeSearchLabel } = useAppConfig();

  const hasMobileSearch = mobileSearchableRoutes.includes(
    outletRoute?.id as unknown as RoutesIdEnum,
  );

  useEffect(() => {
    changeSearchLabel(undefined);
  }, [outletRoute?.id]);

  return (
    <div css={{ zIndex: 2 }}>
      <nav
        css={[
          isMobile ? cssFns.padding(0, 16) : cssFns.padding(0, 60),
          {
            backgroundColor:
              variant === "transparent"
                ? "transparent"
                : principalColors.ebblue,
            color: principalColors.white,
            height,
            zIndex: 2,
            display: "grid",
            gridTemplateColumns: isMobile ? `1fr 1fr 1fr` : "auto 1fr auto",
            columnGap: 16,
            alignItems: "center",
          },
        ]}
      >
        {isMobile && (
          <div>
            <ButtonV2
              onPress={menuState.open}
              variant="icon"
              icon={<MobileMenuBurger color={principalColors.white} />}
              text="Open side menu"
            />
            {menuState.isOpen && (
              <SideMenu
                state={menuState}
                onClose={menuState.close}
                content={{
                  logo: (
                    <Link
                      onClick={menuState.close}
                      css={{
                        color: principalColors.white,
                        textDecoration: "none",
                        justifySelf: "center",

                        ":focus": {
                          outline: "none",
                        },
                      }}
                      to={routePath({ path: "/" })}
                    >
                      <img src="/logo_blue.svg" alt="logo" />
                    </Link>
                  ),
                  main: (
                    <SideMenuMainContent handleCloseMenu={menuState.close} />
                  ),
                  footer: <SideMenuFooter />,
                }}
              />
            )}
          </div>
        )}
        <Link
          css={{
            color: principalColors.white,
            textDecoration: "none",
            justifySelf: "center",
          }}
          to={routePath({ path: "/" })}
        >
          <img
            src="/logo.svg"
            alt="logo"
            // css={{ height: isMobile ? 30 : 40 }}
          />
        </Link>
        {isDesktop && (
          <div css={{ maxWidth: 640, width: "100%", justifySelf: "center" }}>
            {outletRoute?.id !== "home" && <SearchBar variant="multiple" />}
          </div>
        )}

        <div
          css={{
            display: "flex",
            alignItems: "center",
            gap: 36,
            ...(isMobile && { justifyContent: "flex-end" }),
          }}
        >
          {isDesktop && <LoginButton />}
          <LanguageButton />
        </div>
      </nav>
      {hasMobileSearch && isMobile && (
        <div
          css={[
            cssFns.padding(10),
            { backgroundColor: principalColors.ebblue, paddingBlockStart: 0 },
          ]}
        >
          {searchLabel?.isLoading ? (
            <Skeleton height="54px" baseColor={secondaryColors.darkblue} />
          ) : (
            <SearchBar variant="single" searchLabel={searchLabel?.text} />
          )}
        </div>
      )}
    </div>
  );
};
