import { type RefObject, useRef } from "react";
import { type AriaListBoxOptions, useListBox } from "react-aria";

import { cssFns } from "../css";
import { ListBoxItem } from "./item";
import { type ListBoxOptions, OptionsProvider } from "./options";
import { ListBoxSection } from "./section";

export function ListBox<Option>({
  listboxRef,
  listBoxProps: baseListBoxProps,
  options,
}: {
  listboxRef?: RefObject<HTMLUListElement>;
  listBoxProps: AriaListBoxOptions<unknown>;
  options: ListBoxOptions<Option>;
}) {
  const ref = useRef<HTMLUListElement>(null);
  const { listBoxProps } = useListBox(
    baseListBoxProps,
    options.state,
    listboxRef ?? ref,
  );

  return (
    <ul
      {...listBoxProps}
      ref={listboxRef ?? ref}
      css={[
        cssFns.margin("0px"),
        cssFns.padding("0px"),
        cssFns.border({ width: "0px", style: "none" }),
        {
          listStyleType: "none",
          outlineStyle: "none",
        },
      ]}
    >
      <OptionsProvider value={options as ListBoxOptions<unknown>}>
        {[...options.state.collection].map((item) =>
          item.type === "section" ? (
            <ListBoxSection key={item.key} section={item} />
          ) : (
            <ListBoxItem key={item.key} item={item} />
          ),
        )}
      </OptionsProvider>
    </ul>
  );
}
