import type { Node } from "@react-types/shared";
import { useListBoxSection } from "react-aria";

import { cssFns } from "../css";
import { useScreenDetector } from "../screen-type";
import { usePrincipalColors } from "../theme";
import { ListBoxItem } from "./item";
import { useOptions } from "./options";

export function ListBoxSection<Option>({ section }: { section: Node<Option> }) {
  const { isMobile } = useScreenDetector();
  const principalColors = usePrincipalColors();
  const { state } = useOptions();

  const { itemProps, headingProps, groupProps } = useListBoxSection({
    heading: section.rendered,
    "aria-label": section["aria-label"],
  });

  const children = state.collection.getChildren
    ? [...state.collection.getChildren(section.key)]
    : undefined;

  return (
    <>
      <li {...itemProps}>
        {section.rendered && (
          <div
            {...headingProps}
            css={[
              isMobile
                ? [
                    cssFns.padding("12px", "16px"),
                    {
                      paddingBlockStart: "20px",
                    },
                  ]
                : cssFns.padding("12px"),
              isMobile &&
                cssFns.borderBlockEnd({
                  color: principalColors.grayline,
                  style: "solid",
                  width: "1px",
                }),
              {
                color: principalColors.gs4,
              },
            ]}
          >
            {section.rendered}
          </div>
        )}
        <ul
          {...groupProps}
          css={[
            cssFns.margin("0px"),
            cssFns.padding("0px"),
            cssFns.border({ width: "0px", style: "none" }),
            {
              listStyleType: "none",
              outlineStyle: "none",
            },
          ]}
        >
          {children?.map((node) => <ListBoxItem key={node.key} item={node} />)}
        </ul>
      </li>
    </>
  );
}
