import { type SVGProps } from "react";

export const MobileListingCalendarIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M11.4 3.7C11.4 3.3134 11.7134 3 12.1 3C12.4866 3 12.8 3.3134 12.8 3.7V4.4H14.2C15.7464 4.4 17 5.6536 17 7.2H3C3 5.6536 4.2536 4.4 5.8 4.4H7.2V3.7C7.2 3.3134 7.5134 3 7.9 3C8.2866 3 8.6 3.3134 8.6 3.7V4.4H11.4V3.7Z"
        fill="#B2B9BE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 8.6H17V14.2C17 15.7464 15.7464 17 14.2 17H5.8C4.2536 17 3 15.7464 3 14.2V8.6ZM4.96 12.8C4.65072 12.8 4.4 13.0507 4.4 13.36V13.64C4.4 13.9493 4.65072 14.2 4.96 14.2H6.64C6.94928 14.2 7.2 13.9493 7.2 13.64V13.36C7.2 13.0507 6.94928 12.8 6.64 12.8H4.96ZM8.6 13.36C8.6 13.0507 8.85072 12.8 9.16 12.8H10.84C11.1493 12.8 11.4 13.0507 11.4 13.36V13.64C11.4 13.9493 11.1493 14.2 10.84 14.2H9.16C8.85072 14.2 8.6 13.9493 8.6 13.64V13.36ZM4.96 10C4.65072 10 4.4 10.2507 4.4 10.56V10.84C4.4 11.1493 4.65072 11.4 4.96 11.4H6.64C6.94928 11.4 7.2 11.1493 7.2 10.84V10.56C7.2 10.2507 6.94928 10 6.64 10H4.96ZM8.6 10.56C8.6 10.2507 8.85072 10 9.16 10H10.84C11.1493 10 11.4 10.2507 11.4 10.56V10.84C11.4 11.1493 11.1493 11.4 10.84 11.4H9.16C8.85072 11.4 8.6 11.1493 8.6 10.84V10.56ZM13.36 10C13.0507 10 12.8 10.2507 12.8 10.56V10.84C12.8 11.1493 13.0507 11.4 13.36 11.4H15.04C15.3493 11.4 15.6 11.1493 15.6 10.84V10.56C15.6 10.2507 15.3493 10 15.04 10H13.36Z"
        fill="#B2B9BE"
      />
    </svg>
  );
};

export const ClinicBranchPointIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="17" height="23" viewBox="0 0 17 23" fill="none" {...props}>
      <g>
        <path
          d="M17 8.21429C17 13.9643 8.5 23 8.5 23C8.5 23 0 13.9643 0 8.21429C0 3.67766 3.80558 0 8.5 0C13.1944 0 17 3.67766 17 8.21429Z"
          fill="#E7F0F6"
        />
      </g>
    </svg>
  );
};

export const ChevronRightIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="6" height="10" viewBox="0 0 6 10" fill="none" {...props}>
      <path
        d="M1 9L5 5L1 1"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const DesktopArrowRightIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.46967 3.46967C6.76256 3.17678 7.23744 3.17678 7.53033 3.46967L13.5303 9.46967C13.671 9.61032 13.75 9.80109 13.75 10C13.75 10.1989 13.671 10.3897 13.5303 10.5303L7.53033 16.5303C7.23744 16.8232 6.76256 16.8232 6.46967 16.5303C6.17678 16.2374 6.17678 15.7626 6.46967 15.4697L11.9393 10L6.46967 4.53033C6.17678 4.23744 6.17678 3.76256 6.46967 3.46967Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const DesktopArrowLeftIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5303 16.5303C13.2374 16.8232 12.7626 16.8232 12.4697 16.5303L6.46967 10.5303C6.32902 10.3897 6.25 10.1989 6.25 10C6.25 9.80109 6.32902 9.61032 6.46967 9.46967L12.4697 3.46967C12.7626 3.17678 13.2374 3.17678 13.5303 3.46967C13.8232 3.76256 13.8232 4.23744 13.5303 4.53033L8.06066 10L13.5303 15.4697C13.8232 15.7626 13.8232 16.2374 13.5303 16.5303Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const DesktopArrowDownIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5303 6.46967C16.8232 6.76256 16.8232 7.23744 16.5303 7.53033L10.5303 13.5303C10.2374 13.8232 9.76256 13.8232 9.46967 13.5303L3.46967 7.53033C3.17678 7.23744 3.17678 6.76256 3.46967 6.46967C3.76256 6.17678 4.23744 6.17678 4.53033 6.46967L10 11.9393L15.4697 6.46967C15.7626 6.17678 16.2374 6.17678 16.5303 6.46967Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const MobileArrowDownIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.08709 6.83709C3.4532 6.47097 4.0468 6.47097 4.41291 6.83709L10 12.4242L15.5871 6.83709C15.9532 6.47097 16.5468 6.47097 16.9129 6.83709C17.279 7.2032 17.279 7.7968 16.9129 8.16291L10.6629 14.4129C10.2968 14.779 9.7032 14.779 9.33709 14.4129L3.08709 8.16291C2.72097 7.7968 2.72097 7.2032 3.08709 6.83709Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const MobileArrowRight = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.77748 16.2225C7.40751 15.8526 7.40751 15.2527 7.77748 14.8827L12.1602 10.5L7.77748 6.11726C7.40751 5.74729 7.40751 5.14745 7.77748 4.77748C8.14745 4.40751 8.74729 4.40751 9.11726 4.77748L14.1699 9.83011C14.3476 10.0078 14.4474 10.2487 14.4474 10.5C14.4474 10.7513 14.3476 10.9922 14.1699 11.1699L9.11726 16.2225C8.74729 16.5925 8.14745 16.5925 7.77748 16.2225Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const MobileArrowLeft = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7225 15.7225C13.0925 15.3526 13.0925 14.7527 12.7225 14.3827L8.33978 10L12.7225 5.61726C13.0925 5.24729 13.0925 4.64745 12.7225 4.27748C12.3526 3.90751 11.7527 3.90751 11.3827 4.27748L6.33011 9.33011C6.15244 9.50778 6.05263 9.74874 6.05263 10C6.05263 10.2513 6.15244 10.4922 6.33011 10.6699L11.3827 15.7225C11.7527 16.0925 12.3526 16.0925 12.7225 15.7225Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const MobileArrowUpIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.08709 13.1629C3.4532 13.529 4.0468 13.529 4.41291 13.1629L10 7.57583L15.5871 13.1629C15.9532 13.529 16.5468 13.529 16.9129 13.1629C17.279 12.7968 17.279 12.2032 16.9129 11.8371L10.6629 5.58709C10.2968 5.22097 9.7032 5.22097 9.33709 5.58709L3.08709 11.8371C2.72097 12.2032 2.72097 12.7968 3.08709 13.1629Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const ClinicAvatarByDefault = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="34" height="51" viewBox="0 0 34 51" fill="none" {...props}>
      <path
        d="M33 18.1429C33 30.1429 17 49 17 49C17 49 1 30.1429 1 18.1429C1 8.67512 7.53226 1 17 1C26.4677 1 33 8.67512 33 18.1429Z"
        stroke="#E7EDF1"
        strokeWidth="1.5"
      />
      <path
        d="M15.8307 12.0013C15.0999 12.0697 14.5423 12.6798 14.5458 13.4075V15.5165H12.4183C11.6349 15.5165 11 16.1463 11 16.9228V19.0786C11 19.855 11.6349 20.4848 12.4183 20.4848H14.5458V22.5938C14.5458 23.3703 15.1807 23.9994 15.9642 24H18.0364C18.8199 23.9994 19.4542 23.3703 19.4542 22.5938V20.4848H21.5817C22.3651 20.4848 23 19.855 23 19.0786V16.9228C23 16.1463 22.3651 15.5165 21.5817 15.5165H19.4542V13.4075C19.4542 12.6311 18.8193 12.0013 18.0359 12.0013H15.9636C15.9191 11.9996 15.8752 11.9996 15.8307 12.0013Z"
        stroke="#E7EDF1"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export const ClinicAddressIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 6C2.44772 6 2 6.44772 2 7V12C2 12.5523 2.44772 13 3 13H5V9.49991C5 9.22376 5.22386 8.99991 5.5 8.99991H7.5C7.77614 8.99991 8 9.22376 8 9.49991V13H10C10.5523 13 11 12.5523 11 12V7C11 6.44772 10.5523 6 10 6H3Z"
        fill="#B2B9BE"
      />
      <path
        d="M1.14661 7C0.710024 7 0.483137 6.4797 0.780209 6.15977L6.1336 0.394581C6.33142 0.181548 6.66858 0.181548 6.8664 0.394581L12.2198 6.15977C12.5169 6.4797 12.29 7 11.8534 7H1.14661Z"
        fill="#B2B9BE"
      />
    </svg>
  );
};

export const ChevronDownIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="12" height="7" viewBox="0 0 12 7" fill="none" {...props}>
      <path
        d="M1 1L6 6L11 1"
        stroke="#B2B9BE"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ChevronDownIconLg = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.07992 6.83286C3.43649 6.47238 4.0146 6.47238 4.37116 6.83286L9.8125 12.334L15.2538 6.83286C15.6104 6.47238 16.1885 6.47238 16.5451 6.83286C16.9016 7.19335 16.9016 7.77781 16.5451 8.13829L10.4581 14.2921C10.1016 14.6526 9.52345 14.6526 9.16688 14.2921L3.07992 8.13829C2.72336 7.77781 2.72336 7.19335 3.07992 6.83286Z"
        fill="#B2B9BE"
      />
    </svg>
  );
};

export const ClinicOpenIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12ZM6.5 2.5C6.5 2.22386 6.27614 2 6 2C5.72386 2 5.5 2.22386 5.5 2.5V6.5V6.74031L5.68765 6.89043L8.18765 8.89043C8.40328 9.06294 8.71793 9.02798 8.89043 8.81235C9.06294 8.59672 9.02798 8.28207 8.81235 8.10957L6.5 6.25969V2.5Z"
        fill="#51B987"
      />
    </svg>
  );
};

export const ClinicCloseIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12ZM6.5 2.5C6.5 2.22386 6.27614 2 6 2C5.72386 2 5.5 2.22386 5.5 2.5V6.5V6.74031L5.68765 6.89043L8.18765 8.89043C8.40328 9.06294 8.71793 9.02798 8.89043 8.81235C9.06294 8.59672 9.02798 8.28207 8.81235 8.10957L6.5 6.25969V2.5Z"
        fill="#F92D92"
      />
    </svg>
  );
};

export const PhotosIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5 3C5.91015 3 3 5.91015 3 9.5C3 11.0112 3.51573 12.402 4.38078 13.5059L6.70898 11.1777C6.90425 10.9824 7.22083 10.9824 7.41609 11.1777L8.68754 12.4491L11.9902 9.14645C12.1855 8.95118 12.5021 8.95118 12.6973 9.14645L15.5162 11.9653C15.8281 11.205 16 10.3726 16 9.5C16 5.91015 13.0899 3 9.5 3ZM15.0393 12.9026L12.3438 10.2071L9.04109 13.5098C8.84583 13.7051 8.52925 13.7051 8.33398 13.5098L7.06254 12.2384L5.0567 14.2442C6.21896 15.3332 7.78161 16 9.5 16C11.8424 16 13.8953 14.761 15.0393 12.9026ZM6.75004 7.46875C6.75004 7.0718 7.07183 6.75 7.46879 6.75C7.86574 6.75 8.18754 7.0718 8.18754 7.46875C8.18754 7.8657 7.86574 8.1875 7.46879 8.1875C7.07183 8.1875 6.75004 7.8657 6.75004 7.46875ZM7.46879 5.75C6.51955 5.75 5.75004 6.51951 5.75004 7.46875C5.75004 8.41799 6.51955 9.1875 7.46879 9.1875C8.41803 9.1875 9.18754 8.41799 9.18754 7.46875C9.18754 6.51951 8.41803 5.75 7.46879 5.75Z"
        fill="#D8DCDE"
      />
    </svg>
  );
};

export const MobileMenuBurger = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <g clipPath="url(#clip0_11540_17694)">
        <path
          d="M0 4.83709C0 4.37471 0.374831 3.99988 0.837209 3.99988H23.1628C23.6252 3.99988 24 4.37471 24 4.83709C24 5.29947 23.6252 5.6743 23.1628 5.6743H0.837209C0.374831 5.6743 0 5.29947 0 4.83709Z"
          fill="currentColor"
        />
        <path
          d="M0 11.5348C0 11.0724 0.374831 10.6976 0.837209 10.6976H14.2326C14.6949 10.6976 15.0698 11.0724 15.0698 11.5348C15.0698 11.9971 14.6949 12.372 14.2326 12.372H0.837209C0.374831 12.372 0 11.9971 0 11.5348Z"
          fill="currentColor"
        />
        <path
          d="M0.837209 17.3952C0.374831 17.3952 0 17.7701 0 18.2324C0 18.6948 0.374831 19.0696 0.837209 19.0696H18.6977C19.1601 19.0696 19.5349 18.6948 19.5349 18.2324C19.5349 17.7701 19.1601 17.3952 18.6977 17.3952H0.837209Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_11540_17694">
          <rect width="24" height="24" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const MobileMenuSearch = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.6875 17.8446C15.2711 19.0045 13.4602 19.7005 11.4866 19.7005C6.95021 19.7005 3.27272 16.023 3.27272 11.4866C3.27272 6.95022 6.95021 3.27273 11.4866 3.27273C16.023 3.27273 19.7005 6.95022 19.7005 11.4866C19.7005 13.4602 19.0045 15.2712 17.8445 16.6875L21.3058 20.1489C21.6253 20.4684 21.6253 20.9864 21.3058 21.306C20.9863 21.6255 20.4682 21.6255 20.1487 21.3059L16.6875 17.8446ZM18.0642 11.4866C18.0642 15.1193 15.1193 18.0642 11.4866 18.0642C7.85395 18.0642 4.90908 15.1193 4.90908 11.4866C4.90908 7.85396 7.85395 4.90909 11.4866 4.90909C15.1193 4.90909 18.0642 7.85396 18.0642 11.4866Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const VideosIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5 3C5.91015 3 3 5.91015 3 9.5C3 13.0899 5.91015 16 9.5 16C13.0899 16 16 13.0899 16 9.5C16 5.91015 13.0899 3 9.5 3ZM8.52583 6.96227C8.0597 6.71712 7.5 7.05515 7.5 7.58181V11.357C7.5 11.8837 8.05971 12.2217 8.52583 11.9765L12.1149 10.0889C12.6138 9.82657 12.6138 9.11224 12.1149 8.84986L8.52583 6.96227ZM11.1446 9.4694L8.5 10.8603V8.07855L11.1446 9.4694Z"
        fill="#D8DCDE"
      />
    </svg>
  );
};

export const LineCloseIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <g clipPath="url(#clip0_11562_37051)">
        <path
          d="M14 6L6 14"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6 6L14 14"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_11562_37051">
          <rect width="20" height="20" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const LanguageIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path d="M2 8H22" stroke="currentColor" strokeWidth="2" />
      <path d="M2 16H22" stroke="currentColor" strokeWidth="2" />
      <path
        d="M17 12C17 15.1921 16.3509 18.0326 15.3482 20.0381C14.3156 22.1033 13.074 23 12 23C10.926 23 9.68442 22.1033 8.65179 20.0381C7.64906 18.0326 7 15.1921 7 12C7 8.80787 7.64906 5.96739 8.65179 3.96193C9.68442 1.89666 10.926 1 12 1C13.074 1 14.3156 1.89666 15.3482 3.96193C16.3509 5.96739 17 8.80787 17 12Z"
        stroke="currentColor"
        strokeWidth="2"
      />
      <circle cx="12" cy="12" r="11" stroke="currentColor" strokeWidth="2" />
    </svg>
  );
};

export const DesktopPopupDoneIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M14.5801 7.47538C14.8427 7.155 14.7958 6.68245 14.4754 6.4199C14.155 6.15735 13.6825 6.20423 13.4199 6.52461L8.66732 12.324L6.58002 9.77805C6.3174 9.45773 5.84484 9.41095 5.52452 9.67356C5.20419 9.93618 5.15741 10.4087 5.42003 10.7291L8.08744 13.9826C8.22991 14.1564 8.4428 14.2571 8.66752 14.2571C8.89223 14.2571 9.1051 14.1563 9.24753 13.9825L14.5801 7.47538Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10ZM17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const DesktopPopupCancelIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <g opacity="0.6">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10ZM17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85787 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85787 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z"
          fill="currentColor"
        />
        <path
          d="M15.5 4.5L4.5 15.5"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </g>
    </svg>
  );
};

export const AccountCircle = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <circle cx="12" cy="12" r="11" stroke="white" strokeWidth="2" />
      <circle cx="12" cy="10" r="4" stroke="white" strokeWidth="2" />
      <path
        d="M21 19.7694C18.4358 18.0217 15.3372 17 12 17C8.66279 17 5.56421 18.0217 3 19.7694"
        stroke="white"
        strokeWidth="2"
      />
    </svg>
  );
};
