export type Rgba = { r: number; g: number; b: number; a: number };

const rgbRe = /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/i;
const rgbaRe =
  /^rgba\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*([-+]?(?:\d+(?:\.\d+)?|\.\d+))\s*\)$/i;
const hexRe = /^#(?:[A-F0-9]{3}){1,2}$/i;

const parseRgb = (rgb: string) => {
  const match = rgbRe.exec(rgb);
  if (!match || !match[1] || !match[2] || !match[3]) {
    throw RangeError("Invalid RGB string");
  }
  return {
    r: parseInt(match[1], 10),
    g: parseInt(match[2], 10),
    b: parseInt(match[3], 10),
  };
};

const parseRgba = (rgba: string) => {
  const match = rgbaRe.exec(rgba);
  if (!match || !match[1] || !match[2] || !match[3] || !match[4]) {
    throw RangeError("Invalid RGBA string");
  }
  return {
    r: parseInt(match[1], 10),
    g: parseInt(match[2], 10),
    b: parseInt(match[3], 10),
    a: parseFloat(match[4]),
  };
};

const parseHex = (hex: string) => {
  if (!hex.match(hexRe)) {
    throw new Error("Invalid HEX");
  }
  const [r, g, b] =
    hex.length === 4
      ? [
          parseInt(hex.slice(1, 2).repeat(2), 16),
          parseInt(hex.slice(2, 3).repeat(2), 16),
          parseInt(hex.slice(3, 4).repeat(2), 16),
        ]
      : [
          parseInt(hex.slice(1, 3), 16),
          parseInt(hex.slice(3, 5), 16),
          parseInt(hex.slice(5, 7), 16),
        ];

  return { r, g, b, a: 1 };
};

const toRgba = (color: string) => {
  if (rgbaRe.exec(color)) {
    return parseRgba(color);
  }
  if (rgbRe.exec(color)) {
    return { ...parseRgb(color), a: 1 };
  }
  if (hexRe.exec(color)) {
    return parseHex(color);
  }

  throw Error("String is not a color");
};

export const rgba = ({ r, g, b, a }: Rgba): string => {
  return `rgba(${r}, ${g}, ${b}, ${a})`;
};

export const setOpacity = (color: string, opacity: number) => {
  return rgba({ ...toRgba(color), a: opacity });
};

export const linearGradient = (direction: string, stops: string[]): string => {
  return `linear-gradient(${direction},${stops.join(",")})`;
};
