import type { Key } from "@react-types/shared";
import { type ReactNode, type RefObject } from "react";
import { type RootMenuTriggerState, type TreeState } from "react-stately";

import { makeOptions } from "../options-context";
import { type Content, type OverlayContentOptions } from "../overlays/content";

export type MenuOptions<Option> = {
  parentMenuRef: RefObject<HTMLUListElement>;
  state: TreeState<{}>;
  rootTriggerState?: RootMenuTriggerState;
  onSubmenuOpen?: (key: string) => void;
  onSubmenuClose?: (key: string) => void;
  isModalSubmenu?: boolean;
  mobile?: {
    title?: Content<OverlayContentOptions>;
    submenuTitle?: Content<OverlayContentOptions>;
  };
  getOption?: (key: Key) => Option | undefined;
  renderItem?: (props: {
    option: Option;
    rendered: ReactNode;
    isSelected: boolean;
    isFocused: boolean;
  }) => ReactNode;
  closeOnSelect?: boolean;
  onClose?: () => void;
};

export const { OptionsProvider, useOptions } =
  makeOptions<MenuOptions<unknown>>("Menu");
