import { useQuery, type UseQueryOptions } from "@tanstack/react-query";

import { useApi } from "../../context";
import type { AlgoliaSearchResultsType } from "./definitions";

type UseV0AlgoliaSearchParams = {
  query?: string;
  filters?: string;
  url: string;
  apiKey: string;
  applicationId: string;
};
export type UseV0AlgoliaSearchResult = AlgoliaSearchResultsType;

type UseV0AlgoliaSearchOptions = UseQueryOptions<UseV0AlgoliaSearchResult>;

export const useV0AlgoliaSearch = (
  { url, apiKey, applicationId, ...params }: UseV0AlgoliaSearchParams,
  options?: Partial<UseV0AlgoliaSearchOptions>,
) => {
  const { api } = useApi();

  return useQuery({
    ...options,
    queryKey: [url, apiKey, applicationId, params],
    queryFn: () => {
      return api<UseV0AlgoliaSearchResult>(
        {
          url,
          method: "GET",
          headers: {
            "X-Algolia-API-Key": apiKey,
            "X-Algolia-Application-Id": applicationId,
          },
          params,
        },
        {
          200: async (r) => r.data,
        },
      );
    },
  });
};
