import type { AxiosError, AxiosResponse } from "axios";

type GenericError = {
  code: "invalid" | "not_found" | "token_not_valid" | "blank" | string;
  detail: string;
  attr: string | null;
};

export type GenericErrorResponse = {
  type: "client_error" | "validation_error";
  errors: GenericError[];
};

export class FetchError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = "FetchError";
  }
}

export class HttpError extends Error {
  status: number;
  ignore: boolean;

  constructor({
    error,
    ignore = false,
  }: {
    error: AxiosError | AxiosResponse;
    ignore?: boolean;
  }) {
    super("message" in error ? error.message : error.statusText);
    this.name = "HttpError";
    this.status = error.status ?? 400;
    this.ignore = ignore;
  }
}
