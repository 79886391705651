import { css } from "@emotion/react";

export const typo = ({
  level,
  weight = "regular",
  color,
}: {
  level:
    | "title-1"
    | "title-2"
    | "title-3"
    | "title-4"
    | "body-1"
    | "body-2"
    | "body-3";
  weight?: "regular" | "semibold" | "bold";
  color?: string;
}) => {
  const common = {
    fontFamily: `ProximaNova, sans-serif`,
    fontFeatureSettings: "'pnum', 'lnum'",
    fontWeight: {
      regular: 400,
      semibold: 600,
      bold: 700,
    }[weight],
    color,
  };

  switch (level) {
    case "title-1":
      return css({
        ...common,
        fontSize: "36px",
        lineHeight: "40px",
      });
    case "title-2":
      return css({
        ...common,
        fontSize: "30px",
        lineHeight: "37px",
      });
    case "title-3":
      return css({
        ...common,
        fontSize: "22px",
        lineHeight: "27px",
      });
    case "title-4":
      return css({
        ...common,
        fontSize: "18px",
        lineHeight: "22px",
      });
    case "body-1":
      return css({
        ...common,
        fontSize: "16px",
        lineHeight: "20px",
      });
    case "body-2":
      return css({
        ...common,
        fontSize: "14px",
        lineHeight: "18px",
      });
    case "body-3":
      return css({
        ...common,
        fontSize: "12px",
        lineHeight: "14px",
      });
  }
};
