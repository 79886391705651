import { Global } from "@emotion/react";
import { createContext, type ReactNode, useContext } from "react";

import { useScreenDetector } from "./screen-type";

// https://www.figma.com/file/tRXrRnhUgRZoWwsSsBVsGc/doq-%E2%80%93-Web?type=design&node-id=11163-422&mode=design&t=slI3x36TVPw6NHQ7-4

const principalThemeColors = {
  ebblue: "#5194C1",
  grayline: "#E7F0F6",
  gs2: "#333333",
  gs4: "#797979",
  gs8: "#B2B9BE",
  gs10: "#EFEFEF",
  gs12: "#FAFAFA",
  white: "#FFFFFF",
} as const;

const secondaryColors = {
  darkblue: "#3683B6",
  midgreen: "#51B987",
  magenta: "#F92D92",
  red: "#E5312D",
  lightblue: "#74A9CD",
  yellow: "#FFEF0C",
  ginger: "#EFAA52",
} as const;

// Does not account for theming (light/dark)
const ColorsContext = createContext<
  | {
      principal: typeof principalThemeColors;
      secondary: typeof secondaryColors;
    }
  | undefined
>(undefined);

export const usePrincipalColors = () => {
  const context = useContext(ColorsContext);

  if (!context) {
    throw new Error(
      "usePrincipalColors requires ThemeProvider from @hermes/ui at the top of the react tree",
    );
  }

  return context.principal;
};

export const useSecondaryColors = () => {
  const context = useContext(ColorsContext);

  if (!context) {
    throw new Error(
      "useSecondaryColors requires ThemeProvider from @hermes/ui at the top of the react tree",
    );
  }

  return context.secondary;
};

export const ThemeProvider = ({ children }: { children: ReactNode }) => {
  const { isMobile } = useScreenDetector();
  return (
    <>
      <Global
        styles={{
          body: {
            fontFamily: "ProximaNova, sans-serif",
          },
          ".container": {
            maxWidth: isMobile ? "100%" : "1010px !important",
            padding: 0,
          },
          ".modal-dialog": {
            width: isMobile ? "100%" : 600,
            maxWidth: "none",
          },
          "@keyframes fade-in": {
            from: {
              opacity: 0,
            },
            to: {
              opacity: 1,
            },
          },
          "@keyframes fade-out": {
            from: {
              opacity: 1,
            },
            to: {
              opacity: 0,
            },
          },
          "@keyframes slide-in-up": {
            from: { transform: "translateY(200%)" },
            to: { transform: "translateY(0%)" },
          },
          "@keyframes slide-out-up": {
            from: { transform: "translateY(0%)" },
            to: {
              transform: isMobile ? "translateY(70%)" : "translateY(-30%)",
            },
          },
          "@keyframes slide-in-left": {
            from: { transform: "translateX(200%)" },
            to: { transform: "translateX(0%)" },
          },
          "@keyframes slide-out-left": {
            from: { transform: "translateX(0%)" },
            to: { transform: "translateX(100%)" },
          },
        }}
      />
      <ColorsContext.Provider
        value={{
          principal: principalThemeColors,
          secondary: secondaryColors,
        }}
      >
        {children}
      </ColorsContext.Provider>
    </>
  );
};
