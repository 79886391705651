import {
  useQuery,
  useSuspenseQuery,
  type UseSuspenseQueryOptions,
} from "@tanstack/react-query";

import { useApi } from "../../context";
import type { PaginatedResponse } from "../common";
import type { City } from "./definitions";

type UseV0CitiesSuspenseParams = {};
type UseV0CitiesSuspenseResult = {
  cities: City[];
  detected_city: City | null;
};

type UseV0CitiesSuspenseOptions =
  UseSuspenseQueryOptions<UseV0CitiesSuspenseResult>;

export const useV0CitiesSuspense = (
  params: UseV0CitiesSuspenseParams,
  options?: Partial<UseV0CitiesSuspenseOptions>,
) => {
  const url = "/api/v0/cities/";
  const { api } = useApi();

  return useSuspenseQuery({
    ...options,
    queryKey: [url, params],
    queryFn: () => {
      return api<UseV0CitiesSuspenseResult>(
        {
          url,
          method: "GET",
        },
        {
          200: async (r) => r.data,
        },
      );
    },
  });
};

type UseV1CitiesSuspenseParams = {};
type UseV1CitiesSuspenseResult = PaginatedResponse<City[]>;

type UseV1CitiesSuspenseOptions =
  UseSuspenseQueryOptions<UseV1CitiesSuspenseResult>;

export const useV1CitiesSuspense = (
  params: UseV1CitiesSuspenseParams,
  options?: Partial<UseV1CitiesSuspenseOptions>,
) => {
  const url = "/api/v1/cities/";
  const { api } = useApi();

  return useSuspenseQuery({
    ...options,
    queryKey: [url, params],
    queryFn: () => {
      return api<UseV1CitiesSuspenseResult>(
        {
          url,
          method: "GET",
        },
        {
          200: async (r) => r.data,
        },
      );
    },
  });
};

type UseV1CitiesParams = {};
type UseV1CitiesResult = PaginatedResponse<City[]>;

type UseV1CitiesOptions = UseSuspenseQueryOptions<UseV1CitiesResult>;

export const useV1Cities = (
  params: UseV1CitiesParams,
  options?: Partial<UseV1CitiesOptions>,
) => {
  const url = "/api/v1/cities/";
  const { api } = useApi();

  return useQuery({
    ...options,
    queryKey: [url, params],
    queryFn: () => {
      return api<UseV1CitiesResult>(
        {
          url,
          method: "GET",
        },
        {
          200: async (r) => r.data,
        },
      );
    },
  });
};
