import { useV1Services } from "@hermes/api";
import { Flag } from "@hermes/flags";
import { Message } from "@hermes/intl";
import {
  cssFns,
  usePrincipalColors,
  useScreenDetector,
  useSecondaryColors,
} from "@hermes/ui";
import { type SVGProps, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import { useAppConfig } from "#internal/app/config";
import { useRoutePath } from "#internal/app/router";

import { GroupedSpecialties } from "../grouped-specialties";

const ArrowRight = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="13" height="11" viewBox="0 0 13 11" fill="none" {...props}>
      <path
        d="M8 1.5L12 5.5L8 9.5"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 5.5L12 5.5"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const ArrowDown = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.46967 5.46967C2.76256 5.17678 3.23744 5.17678 3.53033 5.46967L8 9.93934L12.4697 5.46967C12.7626 5.17678 13.2374 5.17678 13.5303 5.46967C13.8232 5.76256 13.8232 6.23744 13.5303 6.53033L8.53033 11.5303C8.23744 11.8232 7.76256 11.8232 7.46967 11.5303L2.46967 6.53033C2.17678 6.23744 2.17678 5.76256 2.46967 5.46967Z"
      fill="white"
    />
  </svg>
);

const ArrowUp = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g opacity="0.8">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.46967 10.5303C2.76256 10.8232 3.23744 10.8232 3.53033 10.5303L8 6.06066L12.4697 10.5303C12.7626 10.8232 13.2374 10.8232 13.5303 10.5303C13.8232 10.2374 13.8232 9.76256 13.5303 9.46967L8.53033 4.46967C8.23744 4.17678 7.76256 4.17678 7.46967 4.46967L2.46967 9.46967C2.17678 9.76256 2.17678 10.2374 2.46967 10.5303Z"
        fill="white"
      />
    </g>
  </svg>
);

export const SubHeader = () => {
  const { routePath } = useRoutePath();
  const { isMobile } = useScreenDetector();
  const principalColors = usePrincipalColors();
  const secondaryColors = useSecondaryColors();
  const { city, cityArea } = useAppConfig();
  const outerRef = useRef<HTMLDivElement>(null);
  const innerRef = useRef<HTMLDivElement>(null);

  const [isServicesShown, setIsServicesShown] = useState(false);

  const servicesListQuery = useV1Services(
    {
      city: city.id,
      type: "initial-appointment",
      has_adult_service: false,
      is_primary: true,
      city_area: cityArea?.id,
    },
    { enabled: isServicesShown },
  );

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        outerRef.current &&
        !innerRef.current?.contains(event.target as Node) &&
        !outerRef.current.contains(event.target as Node)
      ) {
        setIsServicesShown(false);
      }
    };

    if (isServicesShown) {
      document.addEventListener("mousedown", handleClickOutside);
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isServicesShown]);

  const commonText = [
    cssFns.typo({ level: "body-1", weight: "semibold" }),
    {
      color: principalColors.white,
      textDecoration: "none",
      cursor: "pointer",
    },
  ];

  if (isMobile) return;

  return (
    <div
      css={[
        cssFns.padding("0px", "60px"),
        {
          height: "60px",
          backgroundColor: secondaryColors.darkblue,
          display: "flex",
          alignItems: "center",
          columnGap: "36px",
          position: "relative",
        },
      ]}
    >
      <span
        ref={outerRef}
        css={[
          ...commonText,
          {
            display: "flex",
            gap: 6,
          },
        ]}
        onClick={() => {
          setIsServicesShown(!isServicesShown);
        }}
      >
        <Message id="1efba235-ae27-4865-b6fe-53b3ab43468d" default="Врачи" />
        {isServicesShown ? <ArrowUp /> : <ArrowDown />}
      </span>
      <Link
        css={commonText}
        to={routePath({
          path: "/clinics/:city",
          params: {
            city: city.slug,
          },
        })}
      >
        <Message id="10165f0b-de4a-4b35-b126-538ddb80df4e" default="Клиники" />
      </Link>
      <Link
        css={commonText}
        to={routePath({
          path: "/procedures/:city",
          params: {
            city: city.slug,
          },
        })}
      >
        <Message
          id="c02e6e87-770a-4158-8d15-bc55b128af38"
          default="Процедуры"
        />
      </Link>
      <div css={{ flexGrow: 1 }} />
      <Flag
        id="92dd44a7-5e13-447a-b440-3e7bf45058e5"
        description="Enable clinics join page"
      >
        <Link
          css={commonText}
          to={routePath({
            path: "/join",
          })}
        >
          <ArrowRight css={{ marginInlineEnd: "8px" }} />
          <Message
            id="86f4763c-96c1-4ff1-ab1a-83b7e13feac2"
            default="Подключить клинику"
          />
        </Link>
      </Flag>
      {servicesListQuery.data && isServicesShown && (
        <div
          css={{
            height: "100vh",
            background: cssFns.setOpacity("#000000", 0.4),
            top: 60,
            left: 0,
            width: "100%",
            zIndex: 30,
            position: "absolute",
          }}
        >
          <div
            ref={innerRef}
            css={[
              cssFns.padding(20, 60),
              {
                backgroundColor: principalColors.white,
                overflow: "auto",
              },
            ]}
          >
            <GroupedSpecialties
              onClick={() => setIsServicesShown(false)}
              minimizedStyle
              services={servicesListQuery.data.results}
            />
          </div>
        </div>
      )}
    </div>
  );
};
