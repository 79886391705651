const data = {
  en: async () => import("@formatjs/intl-relativetimeformat/locale-data/en"),
  hu: async () => import("@formatjs/intl-relativetimeformat/locale-data/hu"),
  kk: async () => import("@formatjs/intl-relativetimeformat/locale-data/kk"),
  ro: async () => import("@formatjs/intl-relativetimeformat/locale-data/ro"),
  ru: async () => import("@formatjs/intl-relativetimeformat/locale-data/ru"),
} as Record<string, Function>;

export async function polyfillRelativeTimeFormat(locales: string[]) {
  await import("@formatjs/intl-relativetimeformat/polyfill-force");

  await Promise.all(
    locales.map((locale) => {
      const localeData = data[locale];

      return localeData?.();
    }),
  );
}
