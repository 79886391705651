import { QueryClient } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

export const ApiDevTools = ({
  client,
  disable,
}: {
  client: QueryClient;
  disable?: boolean;
}) => {
  return !disable && <ReactQueryDevtools client={client} />;
};
