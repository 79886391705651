import type { ReactNode } from "react";
import type { TextFieldAria } from "react-aria";

import { cssFns } from "../../css";
import { usePrincipalColors, useSecondaryColors } from "../../theme";

export const FieldLabel = ({
  labelProps,
  children,
  disabled,
  required,
}: {
  labelProps?: TextFieldAria["labelProps"];
  children: ReactNode;
  disabled?: boolean;
  required?: boolean;
}) => {
  const principalColors = usePrincipalColors();
  const secondaryColors = useSecondaryColors();

  return (
    <div
      css={{
        display: "grid",
      }}
    >
      <label
        css={[
          cssFns.typo({
            level: "body-1",
            weight: "semibold",
          }),
          {
            color: disabled ? principalColors.gs8 : principalColors.gs2,
            overflowX: "hidden",
            overflowY: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            justifySelf: "flex-start",
          },
        ]}
        {...labelProps}
      >
        {children}
        {required && <span css={{ color: secondaryColors.red }}> *</span>}
      </label>
    </div>
  );
};
