import { Message } from "@hermes/intl";
import { cssFns, usePrincipalColors } from "@hermes/ui";

import { AccountCircle } from "./icons";

export const LoginButton = () => {
  const principalColors = usePrincipalColors();

  return (
    <a
      css={{
        display: "flex",
        gap: 6,
        alignItems: "center",
        cursor: "pointer",
        textDecoration: "none",
      }}
      href={import.meta.env.DOQ_CABINET_URL}
    >
      <AccountCircle />
      <span
        css={[
          cssFns.typo({ weight: "regular", level: "body-1" }),
          { color: principalColors.white },
        ]}
      >
        <Message id="d3c493e2-9da2-438e-8601-fec079322bea" default="Войти" />
      </span>
    </a>
  );
};
