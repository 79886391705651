import { Flag } from "@hermes/flags";
import { lazy } from "@hermes/ui";
import { wrapCreateBrowserRouter } from "@sentry/react";
import { type ReactNode, useEffect } from "react";
import {
  createBrowserRouter,
  Navigate,
  type RouteObject,
  useLocation,
  useParams,
} from "react-router-dom";

import { ServicesRedirectPage } from "#internal/pages/services-redirect";
import { countryInfo, useStoredLocale } from "#internal/shared/utils";

import { AppContainer } from "../app-container";
import { RoutesIdEnum } from "./types";

const { NotFoundPage } = lazy(() => import("#internal/pages/not-found/page"));
const { HomePage } = lazy(() => import("#internal/pages/home"));
const { DoctorsListPage } = lazy(
  () => import("#internal/pages/doctors-list/page"),
);
const { DoctorProfilePage } = lazy(
  () => import("#internal/pages/doctor-profile"),
);
const { ClinicsPage } = lazy(() => import("#internal/pages/clinics"));
const { ClinicProfilePage } = lazy(
  () => import("#internal/pages/clinic-profile"),
);
const { AppointmentPage } = lazy(() => import("#internal/pages/appointment"));
const { ProceduresListPage } = lazy(() => import("#internal/pages/procedures"));
const { FeedbackPage } = lazy(() => import("#internal/pages/feedback"));

const { ProceduresByCategory } = lazy(
  () => import("#internal/pages/procedures"),
);
const { MapPage } = lazy(() => import("#internal/pages/map/page"));
const { ContactsPage } = lazy(() => import("#internal/pages/contacts"));

const LangHandler = ({ children }: { children: ReactNode }) => {
  const params = useParams();
  const location = useLocation();
  const routeLocale = params["lang"];
  const [storedLocale, setStoredLocale] = useStoredLocale();

  // Sync store locale with route locale once
  useEffect(() => {
    if (
      routeLocale &&
      routeLocale !== storedLocale &&
      (countryInfo.locales as string[]).includes(routeLocale)
    ) {
      setStoredLocale(routeLocale);
    }
  }, []);

  // Redirect to 404 if route locale is not supported
  if (routeLocale && !(countryInfo.locales as string[]).includes(routeLocale)) {
    return <Navigate to="/404" replace />;
  }

  if (routeLocale !== storedLocale) {
    // Without lang param
    const cleanPath = location.pathname.replace("/" + routeLocale, "");
    const prefix =
      storedLocale !== countryInfo.defaultLocale ? "/" + storedLocale : "";
    const to = `${prefix}${cleanPath}${location.search}`;

    if (to !== `${location.pathname}${location.search}`) {
      return <Navigate to={to} replace />;
    }
  }

  return children;
};

const routes = [
  {
    path: "/:lang?",
    element: (
      <LangHandler>
        <AppContainer />
      </LangHandler>
    ),
    children: [
      {
        index: true,
        element: (
          <Flag
            id="e87753e2-a203-433f-8897-55257c104f1d"
            description="Enable home page route"
            fallback={<NotFoundPage />}
          >
            <HomePage />
          </Flag>
        ),
        id: RoutesIdEnum.HOME,
      },
      {
        path: "doctors/:city/*",
        element: (
          <Flag
            id="ad2fe9e4-05a4-424b-9502-4d8d9aa7c6e5"
            description="Enable doctors list page"
            fallback={<NotFoundPage />}
          >
            <DoctorsListPage />
          </Flag>
        ),
        id: RoutesIdEnum.DOCTORS_LIST,
      },
      {
        path: "doctor/:slug",
        element: (
          <Flag
            id="e875ec8f-4de9-4179-ae86-7621dffc876b"
            description="Enable doctor-details page route"
            fallback={<NotFoundPage />}
          >
            <DoctorProfilePage />
          </Flag>
        ),
        id: RoutesIdEnum.DOCTOR_PROFILE,
      },
      {
        path: "clinics/:city",
        element: (
          <Flag
            id="f7de8dcf-2c10-4017-b8e2-5eab52c93c8d"
            description="Enable clinics page route"
            fallback={<NotFoundPage />}
          >
            <ClinicsPage />
          </Flag>
        ),
        id: RoutesIdEnum.CLINICS_LIST,
      },
      {
        path: "procedures/:city",
        element: (
          <Flag
            id="dba053e6-5061-42d2-bc8d-c0055891eb9d"
            description="Enable procedures list page route"
          >
            <ProceduresListPage />
          </Flag>
        ),
        children: [
          {
            path: "categories",
            element: (
              <Flag
                id="f4f5c635-00da-556a-8ca6-ba47de27467b"
                description="Enable All procedures page"
              >
                <ProceduresByCategory />
              </Flag>
            ),
            id: RoutesIdEnum.PROCEDURES_BY_CATEGORIES,
          },
        ],
        id: RoutesIdEnum.PROCEDURES_LIST,
      },
      {
        path: "clinics/:city/:name",
        element: (
          <Flag
            id="93558975-dfc5-413e-b514-5c4b2f7f8bfa"
            description="Enable clinic profile page route"
            fallback={<NotFoundPage />}
          >
            <ClinicProfilePage />
          </Flag>
        ),
        id: RoutesIdEnum.CLINIC_PROFILE,
      },
      {
        path: "appointments/:appointment_id",
        element: (
          <Flag
            id="58c494f3-b6d1-4f64-bcab-6b213859fd94"
            description="Enable appointment page"
          >
            <AppointmentPage />
          </Flag>
        ),
        id: RoutesIdEnum.APPOINTMENT_PAGE,
      },
      {
        path: "feedback/:appointment_id",
        element: (
          <Flag
            id="f27904f1-2832-45f8-b12c-0660dab35258"
            description="Enable feedback page"
          >
            <FeedbackPage />
          </Flag>
        ),
        id: RoutesIdEnum.FEEDBACK_PAGE,
      },
      {
        path: "map",
        element: <MapPage />,
        id: RoutesIdEnum.MAP_PAGE,
      },
      {
        path: "contacts",
        element: <ContactsPage />,
        id: RoutesIdEnum.CONTACTS_PAGE,
      },
      //Service redirect page
      {
        path: "services/:city/*",
        element: (
          <Flag
            id="27b2a7f0-90ba-4cf7-9db3-afsdfasdfasd"
            description="Enable services-list redirect page"
            fallback={<NotFoundPage />}
          >
            <ServicesRedirectPage />
          </Flag>
        ),
      },

      {
        path: "404",
        element: <NotFoundPage />,
        id: RoutesIdEnum.NOT_FOUND_PAGE,
      },
      {
        path: "*",
        element: <NotFoundPage />,
      },
    ],
  },
] satisfies RouteObject[];

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);

export const router = sentryCreateBrowserRouter(routes);

export const mobileSearchableRoutes = [
  RoutesIdEnum.DOCTORS_LIST,
  RoutesIdEnum.CLINICS_LIST,
  RoutesIdEnum.PROCEDURES_LIST,
  RoutesIdEnum.NOT_FOUND_PAGE,
];
export const cityRoutes = [
  RoutesIdEnum.DOCTORS_LIST,
  RoutesIdEnum.CLINICS_LIST,
  RoutesIdEnum.PROCEDURES_LIST,
  RoutesIdEnum.CLINIC_PROFILE,
];
export const cityAreasRoutes = [RoutesIdEnum.DOCTORS_LIST];

export const pageVisitExceptionsRoutes = [
  RoutesIdEnum.FEEDBACK_PAGE,
  RoutesIdEnum.APPOINTMENT_PAGE,
];
