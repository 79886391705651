import { shouldPolyfill } from "@formatjs/intl-displaynames/should-polyfill";

const data = {
  en: async () => import("@formatjs/intl-displaynames/locale-data/en"),
  hu: async () => import("@formatjs/intl-displaynames/locale-data/hu"),
  kk: async () => import("@formatjs/intl-displaynames/locale-data/kk"),
  ro: async () => import("@formatjs/intl-displaynames/locale-data/ro"),
  ru: async () => import("@formatjs/intl-displaynames/locale-data/ru"),
} as Record<string, Function>;

export async function polyfillDisplayNames(locales: string[]) {
  const localesToPolyfill = locales.map(
    (locale) => [locale, shouldPolyfill(locale)] as const,
  );

  if (localesToPolyfill.some(([_, should]) => should)) {
    await import("@formatjs/intl-displaynames/polyfill-force");
  }

  await Promise.all(
    localesToPolyfill.map(([locale]) => {
      if (!locale) return null;
      const localeData = data[locale];

      return localeData?.();
    }),
  );
}
